import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Loader, Notification } from "rsuite";
import validator from "validator";
import classNames from "classnames";

import { userSelector } from "redux/user/slice";
import { userController } from "api/controllers";

import Input from "components/Input";
import Section from "components/Section";

const errPasswordMessage =
  "Le mot de passe doit contenir au moins 8 caractères dont 1 majuscule, 1 minuscule, 1 nombre, 1 symbole.";
const errConfPasswordMessage = "La saisie doit être identique au mot de passe.";

const AccountScreen = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [password, setPassword] = useState(null);
  const [confPassword, setConfPassword] = useState(null);
  const [errPassword, setErrPassword] = useState(null);
  const [errConfPassword, setErrConfPassword] = useState(
    errConfPasswordMessage
  );

  const userReducer = useSelector(userSelector);
  const user = userReducer.user;

  const onSubmit = async (e) => {
    e.preventDefault();
    if (!errPassword && !errConfPassword) {
      setIsLoading(true);
      const response = await userController.updatePassword(
        { password },
        userReducer.userToken
      );
      setIsLoading(false);
      if (response.data) {
        Notification["success"]({
          title: "Modification de mot de passe",
          duration: 5000,
          description: "Le mot de passe a été modifié avec succés.",
        });
      }
    }
  };

  const isValidPassword = (val) => {
    setPassword(val);
    setErrConfPassword(val !== confPassword ? errConfPasswordMessage : null);
    if (
      validator.isStrongPassword(val, {
        minLength: 8,
        minLowercase: 1,
        minUppercase: 1,
        minNumbers: 1,
        minSymbols: 1,
      })
    ) {
      setErrPassword(null);

      return true;
    } else {
      setErrPassword(errPasswordMessage);
      return false;
    }
  };

  const isValidConfPassword = (val) => {
    setConfPassword(val);
    if (
      validator.isStrongPassword(val, {
        minLength: 8,
        minLowercase: 1,
        minUppercase: 1,
        minNumbers: 1,
        minSymbols: 1,
      }) &&
      val === password
    ) {
      setErrConfPassword(null);
      return true;
    } else {
      setErrConfPassword(errConfPasswordMessage);
      return false;
    }
  };
  console.log(user);
  return (
    <Section className="">
      <form
        className="flex flex-col gap-5 p-5 bg-white rounded-lg shadow-xl"
        onSubmit={onSubmit}
      >
        <span className="text-primary font-extrabold text-lg">Mon compte</span>
        <div className="flex flex-col lg:flex-row gap-2">
          <div className="flex-grow lg:max-w-[50%] flex flex-col gap-2">
            <Input label="Nom" defaultValue={user.lastName} disabled />
            <Input label="Prénom" defaultValue={user.firstName} disabled />
            <Input
              label="Email"
              type="email"
              defaultValue={user.email}
              disabled
            />
            <Input
              label="Adresse"
              defaultValue={
                user.location?.street &&
                user.location?.zipCode &&
                user.location?.city
                  ? user.location?.street +
                    ", " +
                    user.location?.zipCode +
                    " " +
                    user.location?.city
                  : ""
              }
              disabled
            />
          </div>
          <div className="flex-grow lg:max-w-[50%] flex flex-col gap-2">
            <Input label="Tel fixe" defaultValue={user.linePhone} disabled />
            <Input
              label="Tel mobile"
              defaultValue={user.mobilePhone}
              disabled
            />
            <div>
              <Input
                label="Mot de passe"
                className={errPassword ? "!border-red-500" : ""}
                type="password"
                placeholder="Veuillez saisir un mot de passe"
                onChange={isValidPassword}
              />
              <div
                className={classNames(
                  "text-red-500 text-xs",
                  !errPassword ? "hidden" : ""
                )}
              >
                {errPassword}
              </div>
            </div>
            <Input
              label="Confirmer le mot de passe"
              className={errConfPassword ? "!border-red-500" : ""}
              type="password"
              placeholder="Veuillez saisir un mot de passe"
              onChange={isValidConfPassword}
            />
            <div
              className={classNames(
                "text-red-500 text-xs",
                !errPassword ? "hidden" : ""
              )}
            >
              {errConfPassword}
            </div>
          </div>
        </div>
        <div className="flex justify-end">
          <button
            type="submit"
            className="bg-primary text-white rounded py-1 px-5 hover:bg-light"
            disabled={errPassword || errConfPassword}
          >
            {isLoading ? <Loader /> : "Modifier mon compte"}
          </button>
        </div>
      </form>
    </Section>
  );
};

export default AccountScreen;
