import { axiosManager } from "../../services";
import { apiCredentials } from "../apiCredentials";

const endpoint = apiCredentials.url + "/logs/";
const logController = {
  getAll: async (filters = [], access_token) => {
    const config = {
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
    };
    let params = "";
    filters.map((filter) => (params += `&${filter}`));
    return await axiosManager.get(endpoint + "?" + params, config);
  },
};

export default logController;
