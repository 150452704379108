import classNames from "classnames";

import Navbar from "./Navbar";

const Section = ({ title, description, children, className }) => (
  <>
    <Navbar />
    <section className={classNames("p-3", className)}>{children}</section>
  </>
);

export default Section;
