import moment from "moment";

export const Locale = {
  sunday: "Di",
  monday: "Lu",
  tuesday: "Ma",
  wednesday: "Me",
  thursday: "Je",
  friday: "Ve",
  saturday: "Sa",
  ok: "OK",
  today: "Aujourd'hui",
  yesterday: "Hier",
  hours: "Heure",
  minutes: "Minutes",
  seconds: "Secondes",
};

export const tirList = [
  { value: "10", label: "< 10%" },
  { value: "20", label: "< 20%" },

  { value: "30", label: "< 30%" },

  { value: "40", label: "< 40%" },

  { value: "50", label: "< 50%" },

  { value: "60", label: "< 60%" },

  { value: "70", label: "< 70%" },

  { value: "80", label: "< 80%" },

  { value: "90", label: "< 90%" },

  { value: "clear", label: "Tous" },
];

export const diabeteTypes = [
  {
    value: 1164,
    label: "Type 1",
  },
  {
    value: 1165,
    label: "Type 2",
  },
  {
    value: 1166,
    label: "Gestationnel",
  },
  {
    value: 1167,
    label: "Type 1 Grossesse",
  },
  {
    value: 1168,
    label: "Type 2 Grossesse",
  },
  {
    value: 1169,
    label: "LADA",
  },
  {
    value: 1170,
    label: "MODY",
  },
  {
    value: 1171,
    label: "MIDD",
  },
];

export const tabPatient = [
  {
    value: 1,
    label: "Historique des prises en charge",
  },
  {
    value: 2,
    label: "Historique interventions",
  },
  {
    value: 3,
    label: "Graphiques données médicales",
  },
];

export const getInterventionPicto = (type) => {
  switch (type) {
    case "Courrier":
      return "download-courrier.svg";
    case "Évaluation":
      return "download-evaluation.svg";
    case "Téléchargement Dispositif":
      return "download-telechargement-dispositif.svg";
    default:
      return "download.svg";
  }
};

export const labelContract = (plan) => {
  switch (plan) {
    case "ST":
      return "OMNIPOD";
    case "STD":
      return "OMNIPOD DASH";
    case "STMT":
      return "MEDTRUM TOUCHCARE";
    case "AT":
      return "POMPE A INSULINE";
    case "BF MED":
      return "MEDTRONIC 780G/GS3";
    case "BF MEDG4":
      return "MEDTRONIC 780G/G4S";
    case "CANL":
      return "CAPTEUR ENLITE";
    case "CADXG6":
      return "CAPTEUR DEXCOM G6";
    case "CADXG6S":
      return "CAPTEUR DEXCOM G6";
    case "SP BF":
      return "SUSPENSION MEDTRONIC 780G";
    case "SP ST":
      return "SUSPENSION OMNIPOD";
    case "SP STD":
      return "SUSPENSION OMNIPOD DASH";
    case "SP AT":
      return "SUSPENSION POMPE A INSULINE";
    case "BF CAMAPS":
      return "BF YPSOMED CAMAPS";
    case "BF TANDEM":
      return "TANDEM";
  }
  return "";
};

export const contractSettings = (plan) => {
  let settings = {
    label: "",
    careSet: false,
    tegaderm6X7: false,
    tegaderm9X10: false,
    catheter: false,
    autocontrol: false,
  };
  switch (plan) {
    case "ST":
      {
        settings.label = "OMNIPOD";
        settings.careSet = true;
        settings.tegaderm6X7 = true;
        settings.tegaderm9X10 = true;
      }
      break;
    case "STD":
      {
        settings.label = "OMNIPOD DASH";
        settings.careSet = true;
        settings.tegaderm6X7 = true;
        settings.tegaderm9X10 = true;
      }
      break;
    case "STMT":
      {
        settings.label = "MEDTRUM TOUCHCARE";
        settings.careSet = true;
        settings.tegaderm6X7 = true;
        settings.tegaderm9X10 = true;
      }
      break;
    case "AT":
      {
        settings.label = "POMPE A INSULINE";
        settings.careSet = true;
        settings.tegaderm6X7 = true;
        settings.tegaderm9X10 = true;
        settings.catheter = true;
      }
      break;
    case "BF MED":
      {
        settings.label = "MEDTRONIC 780G/GS3";
        settings.careSet = true;
        settings.tegaderm6X7 = true;
        settings.tegaderm9X10 = true;
        settings.catheter = true;
      }
      break;
    case "BF MEDG4":
      {
        settings.label = "MEDTRONIC 780G/G4S";
        settings.careSet = true;
        settings.tegaderm6X7 = true;
        settings.tegaderm9X10 = true;
        settings.catheter = true;
      }
      break;
    case "CANL":
      {
        settings.label = "CAPTEUR ENLITE";
        settings.autocontrol = true;
      }
      break;
    case "CADXG6":
      {
        settings.label = "CAPTEUR DEXCOM G6";
        settings.autocontrol = true;
      }
      break;
    case "CADXG6S":
      {
        settings.label = "CAPTEUR DEXCOM G6";
        settings.autocontrol = true;
      }
      break;
    case "SP BF":
      {
        settings.label = "SUSPENSION MEDTRONIC 780G";
        settings.careSet = true;
        settings.tegaderm6X7 = true;
        settings.tegaderm9X10 = true;
        settings.catheter = true;
      }
      break;
    case "SP ST":
      {
        settings.label = "SUSPENSION OMNIPOD";
        settings.careSet = true;
        settings.tegaderm6X7 = true;
        settings.tegaderm9X10 = true;
      }
      break;
    case "SP STD":
      {
        settings.label = "SUSPENSION OMNIPOD DASH";
        settings.careSet = true;
        settings.tegaderm6X7 = true;
        settings.tegaderm9X10 = true;
      }
      break;
    case "SP AT":
      {
        settings.label = "SUSPENSION POMPE A INSULINE";
        settings.careSet = true;
        settings.tegaderm6X7 = true;
        settings.tegaderm9X10 = true;
      }
      break;
    case "BF CAMAPS":
      {
        settings.label = "BF YPSOMED CAMAPS";
        settings.careSet = true;
        settings.tegaderm6X7 = true;
        settings.tegaderm9X10 = true;
        settings.catheter = true;
      }
      break;
    case "BF TANDEM":
      {
        settings.label = "TANDEM";
        settings.careSet = true;
        settings.tegaderm6X7 = true;
        settings.tegaderm9X10 = true;
        settings.catheter = true;
      }
      break;
  }
  return settings;
};

export const checkSignable = ({
  code,
  finess,
  finessOther,
  socialReason,
  startDate,
  endDate,
  catheter,
}) => {
  let isSignable = false;
  let isFinessValid = false;

  if (
    [
      "ST",
      "STD",
      "STMT",
      "SP ST",
      "SP STD",
      "SP AT",
      "CANL",
      "CADXG6",
      "CADXG6S",
    ].includes(code)
  ) {
    isSignable = true;
  } else if (
    ["AT", "BF MED", "BF MEDG4", "SP BF", "BF CAMAPS", "BF TANDEM"].includes(
      code
    ) &&
    typeof catheter === "string" &&
    catheter.trim() !== ""
  ) {
    isSignable = true;
  }
  if (typeof finess === "string" && finess.trim() !== "") {
    isFinessValid = true;
  } else if (
    typeof finessOther === "string" &&
    finessOther.trim() !== "" &&
    typeof socialReason === "string" &&
    socialReason.trim() !== ""
  ) {
    isFinessValid = true;
  }
  return (
    isSignable &&
    isFinessValid &&
    startDate &&
    moment(startDate).isValid() &&
    endDate &&
    moment(endDate).isValid()
  );
};

export const fakeFiness = [
  {
    id: 0,
    institutionId: 4986,
    isDefault: false,
    postCode: "12345",
    street: "5 AV JEAN JAURES BP 450",
    city: "ST DENIS",
    buildingNumber: "",
    fullAdress: " 5 AV JEAN JAURES BP 450 - 12345 ST DENIS",
    name: "CENTRE HOSPITALIER ST DENIS",
    serviceName: "",
    adeli: "",
    finnesNumber: "110000123",
    comment: null,
  },
];
