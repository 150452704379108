import { axiosManager, sanitizer } from "../../services";
import { apiCredentials } from "../apiCredentials";

const endpoint = apiCredentials.url + "/prescribers/";
const prescriberController = {
  getOne: (doctorId, access_token) => {
    const config = {
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
    };
    return axiosManager.get(endpoint + doctorId, config);
  },
  getAll: async (filters = [], access_token) => {
    const config = {
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
    };
    let params = "";
    filters.map((filter) => (params += `&${filter}`));
    return await axiosManager.get(endpoint + "?" + params, config);
  },
  add: (data, access_token) => {
    data = sanitizer(data);
    const config = {
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
    };
    return axiosManager.post(endpoint, data, config);
  },
  update: (prescriberId, data, access_token) => {
    console.log("data before", data);
    data = sanitizer(data);
    console.log("data after", data);
    const config = {
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
    };
    return axiosManager.update(endpoint + prescriberId, data, config);
  },
  getExportLogin: async (access_token) => {
    const config = {
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
      responseType: "arraybuffer",
    };
    return axiosManager.get(endpoint + "export/login", config);
  },
};

export default prescriberController;
