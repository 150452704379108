import { Loader } from "rsuite";
import { GoChevronDown } from "react-icons/go";
import DataTable from "react-data-table-component";

import columns from "./table/columns";
import customStyles from "./table/customStyles";
import TutoSpeechBubble from "components/TutoSpeechBubble";

const TabIntervention = ({
  isActive = false,
  isLoading = true,
  isTutoMode = false,
  interventions = [],
  accessToken,
}) => {
  return (
    <>
      <DataTable
        columns={columns(accessToken)}
        data={interventions}
        customStyles={customStyles}
        defaultSortFieldId={1}
        defaultSortAsc={false}
        sortIcon={<GoChevronDown color="var(--light)" />}
        striped
        highlightOnHover
        progressPending={isLoading}
        progressComponent={<Loader />}
        dense
        theme="solarized"
        noDataComponent={
          <span className="text-neutral-dark my-5">
            Il n'y a pas d'interventions pour ce patient.
          </span>
        }
        paginationComponentOptions={{
          rowsPerPageText: "Intervention par page:",
          rangeSeparatorText: "sur",
          noRowsPerPage: false,
          selectAllRowsItem: false,
          selectAllRowsItemText: "All",
        }}
      />
      <TutoSpeechBubble
        text="L’onglet « Historique interventions » permet de visualiser toutes les visites effectuées chez le patient sous forme de liste pouvant être triées chronologiquement et ainsi d’accéder à tous les documents associés (CR, évaluation, téléchargement du dispositif)."
        position={{ bottom: "14%", left: "25%" }}
        isVisible={false}
        maxWidth={700}
        pointType="default"
        pointPlacement="top-left"
        className="hidden lg:block"
      />
    </>
  );
};

export default TabIntervention;
