import { useState } from "react";
import { Steps } from "rsuite";
import { AiOutlineClose } from "react-icons/ai";

import ModalComponent from "components/Modal";
import { TutoStep1, TutoStep2, TutoStep3, TutoStep4 } from "./stepTuto";
import classNames from "classnames";

const ModalTuto = ({ id, setIsTutoMode, OpenComponent }) => {
  const [step, setStep] = useState(0);
  const [nextButtonLabel, setNextButtonLabel] = useState(null);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const MAX_STEP = 3;

  const onChange = (nextStep, onClose) => {
    const currentSTEP =
      nextStep < 0 ? 0 : nextStep > MAX_STEP ? MAX_STEP : nextStep;
    if (nextStep === MAX_STEP) {
      setNextButtonLabel("Fermer");
    }
    if (nextStep === MAX_STEP + 1) {
      onClose();
    }
    setStep(currentSTEP);
  };
  const onNext = (onClose) => onChange(step + 1, onClose);
  const onPrevious = () => {
    onChange(step - 1);
    setNextButtonLabel("Suivant");
  };

  const StepsBar = () => {
    return (
      <Steps current={step} className="font-semibold" small={true}>
        <Steps.Item title="Information patient" />
        <Steps.Item title="Timeline" />
        <Steps.Item title="Intervention" />
        <Steps.Item title="Graphique" />
      </Steps>
    );
  };

  const CurrentBody = () => {
    switch (step) {
      case 0:
        return <TutoStep1 />;
      case 1:
        return <TutoStep2 />;
      case 2:
        return <TutoStep3 />;
      case 3:
        return <TutoStep4 />;
      default:
        return null;
    }
  };

  const Content = ({ onCloseModal }) => (
    <div className="rounded-2xl">
      <div className="flex flex-row justify-between items-center p-2 md:p-2 md:px-4 border-b">
        <span className="text-primary font-semibold text-lg font-[PoppinsSemiBold]">
          Mode démo
        </span>
        <AiOutlineClose
          className="delete text-primary hover:text-light cursor-pointer"
          aria-label="close"
          size={24}
          onClick={onCloseModal}
        />
      </div>
      <div className="p-4 md:px-4 flex flex-col gap-5 min-w-[500px] min-h-[300px]">
        <StepsBar />
        <CurrentBody />
      </div>
      <div className="p-4 flex justify-between">
        <button
          className={classNames(
            "rounded text-white py-1 px-5",
            step === 0 ? "bg-secondary" : "bg-primary hover:bg-light"
          )}
          onClick={onPrevious}
          disabled={step === 0}
        >
          Précédent
        </button>
        <button
          className={classNames(
            "rounded  text-white py-1 px-5 flex justify-center items-center",
            buttonDisabled ? "bg-secondary" : "bg-primary hover:bg-light"
          )}
          onClick={() => onNext(onCloseModal)}
          disabled={buttonDisabled}
        >
          {nextButtonLabel ? nextButtonLabel : "Suivant"}
        </button>
      </div>
    </div>
  );

  return (
    <ModalComponent
      id={id}
      Content={Content}
      closeHandle={() => setIsTutoMode(false)}
      OpenComponent={OpenComponent}
    />
  );
};

export default ModalTuto;
