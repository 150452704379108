import { axiosManager } from "../../services";
import { apiCredentials } from "../apiCredentials";

const endpoint = apiCredentials.url + "/statistics/";
const statisticController = {
  getAll: async (filters = [], access_token) => {
    const config = {
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
    };
    let params = "";
    filters.map((filter) => (params += `&${filter}`));
    return await axiosManager.get(endpoint + "?" + params, config);
  },
  getExportStat: async (type, access_token) => {
    const config = {
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
      responseType: "arraybuffer",
    };
    return axiosManager.get(endpoint + "export?type=" + type, config);
  },
};

export default statisticController;
