import Select, { components } from "react-select";
import { BsSquareFill } from "react-icons/bs";

const Dropdown = ({ options, active, setActive, className }) => {
  const { Option, SingleValue } = components;

  const IconOption = (props) => (
    <Option {...props}>
      <div className="flex flex-row items-center cursor-pointer">
        <BsSquareFill className="mr-2 text-white border" />
        {props.data.label}
      </div>
    </Option>
  );

  const IconSingleValue = (props) => {
    return (
      <SingleValue {...props}>
        <div className="flex flex-row items-center cursor-pointer">
          <BsSquareFill className="mr-2 text-white border" />
          {props.data.label}
        </div>
      </SingleValue>
    );
  };

  return (
    <Select
      className={className}
      options={options}
      isSearchable={false}
      theme={(theme) => ({
        ...theme,
        borderRadius: 5,
        colors: {
          ...theme.colors,
          primary: "#52BDC8",
          primary25: "#EEEEEE",
          primary50: "#AFAFAF",
        },
      })}
      styles={{
        singleValue: (base) => ({
          ...base,
          "&:hover": {
            borderColor: "white",
          },
        }),
      }}
      value={options.filter((option) => option.value === active)}
      components={{ Option: IconOption, SingleValue: IconSingleValue }}
      onChange={(newValue) => setActive(newValue?.value || 1)}
    />
  );
};

export default Dropdown;
