export const Locale = {
  sunday: "Di",
  monday: "Lu",
  tuesday: "Ma",
  wednesday: "Me",
  thursday: "Je",
  friday: "Ve",
  saturday: "Sa",
  ok: "OK",
  today: "Aujourd'hui",
  yesterday: "Hier",
  hours: "Heure",
  minutes: "Minutes",
  seconds: "Secondes",
};

export const tirList = [
  { value: "10", label: "< 10%" },
  { value: "20", label: "< 20%" },

  { value: "30", label: "< 30%" },

  { value: "40", label: "< 40%" },

  { value: "50", label: "< 50%" },

  { value: "60", label: "< 60%" },

  { value: "70", label: "< 70%" },

  { value: "80", label: "< 80%" },

  { value: "90", label: "< 90%" },

  { value: "clear", label: "Tous" },
];

export const diabeteTypes = [
  {
    value: 1164,
    label: "Type 1",
  },
  {
    value: 1165,
    label: "Type 2",
  },
  {
    value: 1166,
    label: "Gestationnel",
  },
  {
    value: 1167,
    label: "Type 1 Grossesse",
  },
  {
    value: 1168,
    label: "Type 2 Grossesse",
  },
  {
    value: 1169,
    label: "LADA",
  },
  {
    value: 1170,
    label: "MODY",
  },
  {
    value: 1171,
    label: "MIDD",
  },
];
