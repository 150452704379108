import { axiosManager, sanitizer } from "../../services";
import { apiCredentials } from "../apiCredentials";

const endpoint = apiCredentials.url + "/files/";
const fileController = {
  create: (data, access_token) => {
    data = sanitizer(data);
    const config = {
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
    };
    return axiosManager.post(endpoint, data, config);
  },
  getOne: (fileId, access_token) => {
    const config = {
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
      responseType: "blob",
    };
    return axiosManager.get(endpoint + fileId, config);
  },
  getMetadata: (fileId, access_token) => {
    const config = {
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
      responseType: "blob",
    };
    return axiosManager.get(endpoint + fileId + "?metadata=true", config);
  },
  getAll: async (filters = [], access_token) => {
    const config = {
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
      responseType: "blob",
    };
    let params = "";
    filters.map((filter) => (params += filter));
    return await axiosManager.get(endpoint + "?" + params, config);
  },
  deleteOne: (fileId, access_token) => {
    const config = {
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
    };
    return axiosManager.delete(endpoint + fileId, config);
  },
};

export default fileController;
