import React, { useState, useEffect } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { useSelector, useDispatch } from "react-redux";
import { Loader } from "rsuite";
import DataTable from "react-data-table-component";
import { GoChevronDown } from "react-icons/go";

import Section from "components/Section";
import columns from "./columns";
import customStyles from "./customStyles";
import conditionalRowStyles from "./conditionalRowStyles";

import { userSelector } from "../../redux/user/slice";
import { monitoringController } from "../../api/controllers";
import { logout } from "../../redux/user/slice";
import { checkStatus } from "../../services";

const MonitoringScreen = () => {
  const [monitoring, setMonitoring] = useState({ docs: [] });
  const [isLoading, setIsLoading] = useState(true);
  const [limit, setLimit] = useState(25);
  const [sort, setSort] = useState('{"startDate":-1, "startDate":-1}');
  const [currentPage, setCurrentPage] = useState(1);
  const [searchValue, setSearchValue] = useState(null);
  const [filters, setFilters] = useState([
    `limit=${limit}`,
    `page=${currentPage}`,
    `sort=${sort}`,
    `nor={"startDate":{"$lt":{}}}`,
    `nor={"startDate":{"$gte":{}}}`,
  ]);

  const userReducer = useSelector(userSelector);
  const dispatch = useDispatch();

  useEffect(() => {
    async function defaultValue() {
      const response = await getMonitoring(filters);
      setMonitoring(response.data);
    }
    defaultValue();
  }, []);

  const getMonitoring = async (applyFilter = []) => {
    setIsLoading(true);
    const response = await monitoringController.getAll(
      applyFilter,
      userReducer.userToken
    );
    if (!checkStatus(response.status)) dispatch(logout());
    setIsLoading(false);
    return response;
  };

  const handleFilterMonitoringChange = async (
    value = {
      newLimit: limit,
      newSort: sort,
      newPage: currentPage,
      searchMode: false,
      searchValue: searchValue,
      date: null,
    }
  ) => {
    let newFilters = filters;
    newFilters.map((filter, index) => {
      if (
        value.newLimit &&
        value.newLimit !== limit &&
        filter === `limit=${limit}`
      ) {
        newFilters[index] = filter = `limit=${value.newLimit}`;
        setLimit(value.newLimit);
      }
      if (value.newSort && filter === `sort=${sort}`) {
        newFilters[index] = filter = `sort=${value.newSort}`;
        setSort(value.newSort);
      }
      if (value.newPage && filter === `page=${currentPage}`) {
        newFilters[index] = filter = `page=${value.newPage}`;
        setCurrentPage(value.newPage);
      }
      return filter;
    });
    setFilters(newFilters);
    const response = await getMonitoring(filters.concat(filters.prescriberFilter));
    setMonitoring(response.data);
  };

  return (
    <Section className="flex flex-col gap-3">
      <div className="flex flex-row justify-between">
        <span className="text-primary font-extrabold text-lg">
          Monitoring synchronisation
        </span>
      </div>
      <div id="dataTable" className="w-full h-full rounded">
        <DataTable
          columns={columns()}
          data={monitoring.docs || []}
          customStyles={customStyles}
          conditionalRowStyles={conditionalRowStyles}
          noDataComponent={
            <span className="w-full text-center font-semibold">
              Pas de données
            </span>
          }
          defaultSortFieldId={1}
          defaultSortAsc={false}
          sortIcon={<GoChevronDown color="var(--light)" />}
          striped
          theme="solarized"
          highlightOnHover
          pointerOnHover
          pagination
          paginationPerPage={limit}
          paginationRowsPerPageOptions={[25, 50, 100]}
          progressPending={isLoading}
          progressComponent={<Loader className="w-full text-center" />}
          paginationServer
          paginationDefaultPage={monitoring.page}
          paginationServerOptions={{
            persistSelectedOnPageChange: true,
            persistSelectedOnSort: true,
          }}
          paginationTotalRows={monitoring.totalDocs}
          onChangePage={async (page) =>
            await handleFilterMonitoringChange({ newPage: page })
          }
          onChangeRowsPerPage={async (newPerPage) =>
            await handleFilterMonitoringChange({
              newLimit: newPerPage,
              newPage: 1,
            })
          }
          sortServer
          onSort={async (column, sortDirection) =>
            await handleFilterMonitoringChange({
              newSort: `{"${column.sortfield}":${
                sortDirection === "desc" ? -1 : 1
              }}`,
              newPage: 1,
            })
          }
          dense
          paginationComponentOptions={{
            rowsPerPageText: "Erreur par page:",
            rangeSeparatorText: "sur",
            noRowsPerPage: false,
            selectAllRowsItem: false,
            selectAllRowsItemText: "All",
          }}
        />
      </div>
    </Section>
  );
};

export default MonitoringScreen;
