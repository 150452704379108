import { BsSquareFill } from "react-icons/bs";

import Dropdown from "./Dropdown";

const Tab = ({ data, activeTab, setActiveTab }) => (
  <div className="border-b border-b-[color:#D3D7DA]">
    <ul className="hidden lg:flex bg-[color:#EEF1F4] rounded-t-lg w-fit m-0">
      {data.map((option, index) => (
        <li
          key={index}
          className={`relative block cursor-pointer truncate py-2 px-5 font-semibold ${
            activeTab === option.value
              ? "lg:text-white bg-light rounded-t-lg"
              : "hover:opacity-50"
          }`}
          onClick={() => setActiveTab(option.value)}
        >
          <div className="flex items-center justify-center space-x-2">
            <BsSquareFill size={13} className="text-white rounded" />
            <span className="text-sm">{option.label}</span>
          </div>
          {activeTab === option.value ? (
            <span className="absolute inset-x-0 -bottom-px h-px w-full bg-light"></span>
          ) : null}
        </li>
      ))}
    </ul>
    <Dropdown
      className="lg:hidden px-2 mb-5"
      options={data}
      active={activeTab}
      setActive={setActiveTab}
    />
  </div>
);

export default Tab;
