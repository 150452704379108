import React from "react";

const TutoSpeechBubble = ({
  text,
  position,
  className,
  pointType = "disabled",
  pointPlacement,
  isVisible,
  minWidth,
  maxWidth,
}) => {
  const point = (type) => {
    switch (String(type)) {
      case "default":
        return <div style={pointStyle(pointPlacement)}></div>;
      case "disabled":
        return null;
      default:
        return null;
    }
  };
  const pointStyle = (placement) => {
    let defaultStyle = {
      width: 0,
      height: 0,
      borderLeft: "8px solid transparent",
      borderRight: "8px solid transparent",
      borderTop: "40px solid var(--dark)",
      position: "absolute",
    };
    let positionStyle = {};

    switch (String(placement)) {
      case "top-left":
        positionStyle = {
          top: -30,
          left: -3,
          transform: "rotate(-220deg)",
          WebkitTransform: "rotate(-220deg)",
          MozTransition: "rotate(-220deg)",
          MsTransform: "rotate(-220deg)",
          OTransform: "rotate(-220deg)",
        };
        break;
      case "top-right":
        positionStyle = {
          top: -30,
          right: -3,
          transform: "rotate(220deg)",
          WebkitTransform: "rotate(220deg)",
          MozTransition: "rotate(220deg)",
          MsTransform: "rotate(220deg)",
          OTransform: "rotate(220deg)",
        };
        break;
      case "bottom-left":
        positionStyle = {
          bottom: -30,
          left: -3,
          transform: "rotate(42deg)",
          WebkitTransform: "rotate(42deg)",
          MozTransition: "rotate(42deg)",
          MsTransform: "rotate(42deg)",
          OTransform: "rotate(42deg)",
        };
        break;
      case "bottom-right":
        positionStyle = {
          bottom: -30,
          right: -3,
          transform: "rotate(-42deg)",
          WebkitTransform: "rotate(-42deg)",
          MozTransition: "rotate(-42deg)",
          MsTransform: "rotate(-42deg)",
          OTransform: "rotate(-42deg)",
        };
      case "bottom-right":
        positionStyle = {
          bottom: -30,
          right: -3,
          transform: "rotate(-42deg)",
          WebkitTransform: "rotate(-42deg)",
          MozTransition: "rotate(-42deg)",
          MsTransform: "rotate(-42deg)",
          OTransform: "rotate(-42deg)",
        };
      default:
        positionStyle = {};
    }
    return { ...defaultStyle, ...positionStyle };
  };
  return (
    <div
      style={{
        visibility: isVisible ? "visible" : "hidden",
        minWidth: minWidth ? minWidth : 300,
        maxWidth: maxWidth ? maxWidth : undefined,
        border: "solid",
        borderWidth: 1,
        backgroundColor: "var(--dark)",
        borderRadius: 5,
        padding: 10,
        borderColor: "var(--dark)",
        position: "absolute",
        top: position?.top || undefined,
        right: position?.right || undefined,
        left: position?.left || undefined,
        bottom: position?.bottom || undefined,
        zIndex: 30,
      }}
      className={className}
    >
      <p
        className="text-white font-semibold text-xs"
        style={{
          whiteSpace: "pre-wrap",
        }}
      >
        {text}
      </p>
      {point(pointType)}
    </div>
  );
};

export default TutoSpeechBubble;
