import { configureStore } from "@reduxjs/toolkit";
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import storage from "redux-persist/lib/storage";
//import { CookieStorage } from "redux-persist-cookie-storage";
//import Cookies from "cookies-js";

import userReducer from "./user/slice";
import appReducer from "./app/slice";

const persistUserConfig = {
  key: "user",
  version: 1,
  storage,
};

const persistAppConfig = {
  key: "app",
  version: 1,
  storage,
};

const persistedUserReducer = persistReducer(persistUserConfig, userReducer);

const persistedAppReducer = persistReducer(persistAppConfig, appReducer);

export const store = configureStore({
  reducer: {
    userReducer: persistedUserReducer,
    appReducer: persistedAppReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

export let persistor = persistStore(store, window.PRELOADED_STATE);
