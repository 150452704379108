import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Toggle, Notification, Loader } from "rsuite";
import classNames from "classnames";

import Section from "components/Section";
import Tab from "components/Tab";

import { userSelector } from "../../redux/user/slice";
import {
  patientController,
  interventionController,
  prescriberController,
} from "../../api/controllers";
import { logout } from "../../redux/user/slice";
import { checkStatus } from "../../services";
import { TabPriseEnCharge, TabIntervention, TabGraph } from "./tabContent";
import { tabPatient, fakeFiness } from "./constants";

import CardPrescription from "./CardPrescription";
import CardTraitement from "./CardTraitement";
import CardMateriel from "./CardMateriel";
import CardRenewal from "./CardRenewal";
import PatientInfo from "./PatientInfo";
import { metricsModel } from "../../models";
import TutoSpeechBubble from "components/TutoSpeechBubble";
import Error404 from "../Error404";
import ModalTuto from "./ModalTuto";

const PatientScreen = () => {
  const paramId = useParams().id;
  const userReducer = useSelector(userSelector);
  const id =
    userReducer.user.role === "patient"
      ? userReducer.user.roleId
      : !isNaN(paramId)
      ? paramId
      : null;
  const [isTutoMode, setIsTutoMode] = useState(false);
  const [activeTab, setActiveTab] = useState(1);
  const [patient, setPatient] = useState(null);
  const [metrics, setMetrics] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  const [isModalLoading, setIsModalLoading] = useState(false);
  const [downloadBtnHover, setDownloadBtnHover] = useState(false);
  const [settings, setSettings] = useState({});
  const [careSetChecked, setCareSetChecked] = useState(true);
  const [tegaderm6X7Checked, setTegaderm6X7Checked] = useState(true);
  const [tegaderm9X10Checked, setTegaderm9X10Checked] = useState(true);
  const [memberId, setMemberId] = useState();
  const [otherFiness, setOtherFiness] = useState(
    process.env.REACT_APP_DEMO
      ? false
      : userReducer.roleInfo?.finess
      ? userReducer.roleInfo?.finess.length === 0
      : true
  );
  const [finessSelectValue, setFinessSelectValue] = useState(
    process.env.REACT_APP_DEMO
      ? {
          label: fakeFiness[0].finnesNumber + " - " + fakeFiness[0].name,
          value: "0",
        }
      : userReducer.roleInfo?.finess.length > 0
      ? {
          label:
            userReducer.roleInfo?.finess[0].finnesNumber +
            " - " +
            userReducer.roleInfo?.finess[0].name,
          value: "0",
        }
      : { label: "", value: "other" }
  );
  const [finessOther, setFinessOther] = useState("");
  const [socialReason, setSocialReason] = useState("");
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [careSet, setCareSet] = useState(1);
  const [tegaderm6X7, setTegaderm6X7] = useState(1);
  const [tegaderm9X10, setTegaderm9X10] = useState(1);
  const [autocontrol, setAutocontrol] = useState(false);
  const [catheter, setCatheter] = useState("");
  const [missingItems, setMissingItems] = useState(false);
  const [prescriber, setPrescriber] = useState({
    _id: userReducer.user?.roleId,
    firstName: userReducer.user?.firstName,
    lastName: userReducer.user?.lastName,
    rpps: userReducer.roleInfo?.rpps,
    email: userReducer.user?.email,
  });
  const [finess, setFiness] = useState(
    process.env.REACT_APP_DEMO ? fakeFiness : userReducer.roleInfo?.finess || []
  );

  const dispatch = useDispatch();
  useEffect(() => {
    async function defaultValue() {
      if (id) {
        const patientData = await getPatient(id);
        setPatient(patientData);
        setMetrics(await getMetrics(id));
        if (userReducer.user?.role === "admin") {
          const prescriberData = await getPrescriber(patientData.prescriberId);
          setPrescriber({
            _id: prescriberData._id,
            firstName: userReducer.user?.firstName,
            lastName: userReducer.user?.lastName,
            rpps: "12345678910",
            email: userReducer.user?.email,
          });
          if (
            !process.env.REACT_APP_DEMO &&
            prescriberData.finess &&
            Array.isArray(prescriberData.finess)
          ) {
            setFiness(prescriberData.finess);
            setOtherFiness(prescriberData.finess.length === 0);
            setFinessSelectValue(
              prescriberData.finess.length > 0
                ? {
                    label:
                      prescriberData.finess[0].finnesNumber +
                      " - " +
                      prescriberData.finess[0].name,
                    value: "0",
                  }
                : { label: "", value: "other" }
            );
          }
        }
      } else {
        setIsLoading(false);
        Notification["error"]({
          title: "Erreur detectée",
          duration: 5000,
          description: "Non trouvé",
        });
      }
    }
    defaultValue();
  }, []);

  const getPatient = async (id) => {
    setIsLoading(true);
    const response = await patientController.getOne(id, userReducer.userToken);
    if (!checkStatus(response.status)) dispatch(logout());
    setIsLoading(false);
    return response.data;
  };

  const getPrescriber = async (id) => {
    setIsLoading(true);
    const response = await prescriberController.getOne(
      id,
      userReducer.userToken
    );
    if (!checkStatus(response.status)) dispatch(logout());
    setIsLoading(false);
    return response.data;
  };

  const getMetrics = async (id) => {
    const response = await interventionController.getMetrics(
      id,
      userReducer.userToken
    );
    if (!checkStatus(response.status)) dispatch(logout());
    return response.data != null ? response.data : {};
  };

  const ContractButton = ({ type, className }) => {
    let config = null;
    if (type === "EXPERTIS") {
      config = { imgSrc: "/icons/expertis.png", bgColor: "#000063" };
    } else if (type === "INTENSIS") {
      config = { imgSrc: "/icons/intensis.png", bgColor: "#ffab19" };
    } else if (type === "TELESIS") {
      config = { imgSrc: "/icons/telesis.png", bgColor: "#E8477D" };
    }

    if (config) {
      return (
        <button
          className={classNames(
            className,
            "flex flex-row gap-2 items-center justify-center cursor-default max-h-[38px]"
          )}
          style={{ backgroundColor: config.bgColor }}
        >
          <img src={config.imgSrc} className="h-[26px]" alt="contract" />
          {type ? type : "EXPERTIS"}
        </button>
      );
    } else {
      return null;
    }
  };

  if (isLoading) {
    return <Loader className="p-4 mx-auto" />;
  } else if (patient === null) {
    return <Error404 />;
  }

  return (
    <Section>
      <div className="bg-white rounded-lg shadow-xl flex flex-col gap-3">
        <div className="flex flex-row justify-between p-5 pb-0">
          <ModalTuto
            setIsTutoMode={setIsTutoMode}
            OpenComponent={({ onClick }) => (
              <Toggle
                checked={isTutoMode}
                onChange={(checked) => {
                  if (checked) onClick();
                  setIsTutoMode(checked);
                }}
                checkedChildren="Mode démo"
                unCheckedChildren="Mode démo"
              />
            )}
          />
          <div className="flex flex-row gap-1">
            <ContractButton
              type={
                patient.contract?.type !== "EXPERTIS"
                  ? patient.contract?.type
                  : null
              }
              className="p-2 text-white rounded"
            />
            <ContractButton
              type="EXPERTIS"
              className="p-2 text-white rounded"
            />
          </div>
        </div>

        <div className="flex flex-col lg:flex-row justify-between gap-5 lg:gap-2 p-5 pt-0">
          <PatientInfo patient={patient} isTutoMode={isTutoMode} />
          <div className="flex flex-col lg:flex-row gap-3">
            <div className="flex flex-col gap-3">
              <CardTraitement
                traitement={{
                  installation: patient.firstContract?.startDate || "--",
                  firstHbA1c: patient.firstIntervention?.metrics?.HbA1c || "--",
                  insuline: patient.firstIntervention?.metrics?.diq || "--",
                  hba1c: patient.lastIntervention?.metrics?.HbA1c || "--",
                  insulineType: patient.insulinType
                    ? patient.insulinType
                    : patient.lastIntervention
                    ? patient.lastIntervention.insulinType &&
                      typeof patient.lastIntervention.insulinType === "string"
                      ? patient.lastIntervention.insulinType.replace(
                          new RegExp('"', "g"),
                          ""
                        )
                      : null
                    : null,
                  diabeteType: patient.diabeteType || "--",
                }}
              />
              <CardMateriel
                materiels={patient.pump ? [patient.pump] : []}
                catheter={
                  patient.catheter
                    ? patient.catheter
                    : patient.lastIntervention
                    ? patient.lastIntervention.catheterType &&
                      typeof patient.lastIntervention.catheterType === "string"
                      ? patient.lastIntervention.catheterType.replace(
                          new RegExp('"', "g"),
                          ""
                        )
                      : null
                    : null
                }
                sensorType={
                  patient.sensorType
                    ? patient.sensorType
                    : patient.lastIntervention
                    ? patient.lastIntervention.sensorType &&
                      typeof patient.lastIntervention.sensorType === "string"
                      ? patient.lastIntervention.sensorType.replace(
                          new RegExp('"', "g"),
                          ""
                        )
                      : null
                    : null
                }
                isLoading={false}
              />
            </div>
            <div className="flex flex-col gap-3">
              <CardPrescription
                prescriptions={patient.currentPrescriptions || []}
                isLoading={false}
                accessToken={userReducer.userToken}
              />
              <CardRenewal
                prescriptions={patient.renewablePrescriptions || []}
                prescriber={prescriber}
                finess={finess}
                patient={{
                  _id: patient._id,
                  fullName: patient.fullName,
                  firstName: patient.firstName,
                  lastName: patient.lastName,
                  socialSecurityNumber: patient.socialSecurityNumber,
                  catheter: patient.catheter
                    ? patient.catheter
                    : patient.lastIntervention
                    ? patient.lastIntervention.catheterType &&
                      typeof patient.lastIntervention.catheterType === "string"
                      ? patient.lastIntervention.catheterType.replace(
                          new RegExp('"', "g"),
                          ""
                        )
                      : ""
                    : "",
                }}
                accessToken={userReducer.userToken}
                isModalLoading={isModalLoading}
                setIsModalLoading={setIsModalLoading}
                downloadBtnHover={downloadBtnHover}
                setDownloadBtnHover={setDownloadBtnHover}
                settings={settings}
                setSettings={setSettings}
                careSetChecked={careSetChecked}
                setCareSetChecked={setCareSetChecked}
                tegaderm6X7Checked={tegaderm6X7Checked}
                setTegaderm6X7Checked={setTegaderm6X7Checked}
                tegaderm9X10Checked={tegaderm9X10Checked}
                setTegaderm9X10Checked={setTegaderm9X10Checked}
                otherFiness={otherFiness}
                setOtherFiness={setOtherFiness}
                finessOther={finessOther}
                setFinessOther={setFinessOther}
                socialReason={socialReason}
                setSocialReason={setSocialReason}
                finessSelectValue={finessSelectValue}
                setFinessSelectValue={setFinessSelectValue}
                startDate={startDate}
                setStartDate={setStartDate}
                endDate={endDate}
                setEndDate={setEndDate}
                careSet={careSet}
                setCareSet={setCareSet}
                tegaderm6X7={tegaderm6X7}
                setTegaderm6X7={setTegaderm6X7}
                tegaderm9X10={tegaderm9X10}
                setTegaderm9X10={setTegaderm9X10}
                autocontrol={autocontrol}
                setAutocontrol={setAutocontrol}
                memberId={memberId}
                setMemberId={setMemberId}
                catheter={catheter}
                setCatheter={setCatheter}
                missingItems={missingItems}
                setMissingItems={setMissingItems}
                hasSignAccess={
                  process.env.NODE_TYPE !== "production" ||
                  (process.env.NODE_TYPE === "production" &&
                    userReducer.role === "prescriber")
                }
              />
            </div>
          </div>
        </div>
        <Tab
          data={tabPatient}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
        />
        <div className="p-5 pt-0">
          {activeTab === 1 ? (
            patient.contracts &&
            patient.contracts.length > 0 &&
            patient.prescriptions &&
            patient.prescriptions.length > 0 &&
            patient.interventions &&
            patient.interventions.length > 0 ? (
              <>
                <TutoSpeechBubble
                  text="L’onglet « Historique de prises en charge » contient une Timeline permettant de visualiser les interventions effectuées chez le patient. En cliquant sur un pictogramme le compte-rendu du rdv s’affichera à l’écran. Il sera alors possible de le télécharger ou l’imprimer."
                  position={{ bottom: "12%", left: "10%" }}
                  isVisible={false}
                  pointType="default"
                  pointPlacement="top-left"
                  className="hidden lg:block"
                  maxWidth={500}
                />
                <TabPriseEnCharge
                  isActive={activeTab === 1}
                  firstContract={patient.firstContract}
                  contracts={patient.contracts}
                  prescriptions={patient.prescriptions}
                  interventions={patient.interventions}
                />
              </>
            ) : (
              <div className="text-neutral-dark w-full text-center my-5">
                Il n'y a pas encore de prise en charge pour ce patient.
              </div>
            )
          ) : null}
          {activeTab === 2 ? (
            <TabIntervention
              isActive={activeTab === 2}
              interventions={patient.interventions || []}
              isLoading={false}
              accessToken={userReducer.userToken}
              isTutoMode={isTutoMode ? (activeTab === 2 ? true : false) : false}
            />
          ) : null}
          {activeTab === 3 ? (
            JSON.stringify(metrics) !== JSON.stringify(metricsModel) ? (
              <TabGraph
                isActive={activeTab === 3}
                metrics={metrics}
                isTutoMode={isTutoMode}
              />
            ) : (
              <div className="text-neutral-dark w-full text-center my-5">
                Aucune donnée médicale n'a été renseignée pour ce patient.
              </div>
            )
          ) : null}
        </div>
        <TutoSpeechBubble
          text="Des onglets permettent d’accéder aux données en lien avec le suivi du patient"
          position={{ bottom: "28%", right: "5%" }}
          isVisible={false}
          pointPlacement="bottom-right"
          className="hidden lg:block"
        />
      </div>
      <TutoSpeechBubble
        text="Les informations concernant sa/ses prescriptions en cours et à venir, son traitement et son matériel"
        position={{ top: 72, right: "11%" }}
        isVisible={false}
        pointPlacement="bottom-right"
        className="hidden lg:block"
        minWidth={665}
      />
      <TutoSpeechBubble
        text="En cliquant sur le nom d’un patient dans la liste, sa fiche apparait. La partie supérieure est organisée par pavé contenant toutes les informations du patient."
        position={{ top: 162, left: "18%" }}
        isVisible={false}
        pointType="disabled"
        className="hidden lg:block"
        maxWidth={400}
      />
    </Section>
  );
};

export default PatientScreen;
