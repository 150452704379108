export const fileModel = {
  _id: "",
  type: "",
  uploadedAt: new Date(),
  name: "",
  realName: "",
  uploadedBy: {
    _id: "",
    name: "",
    firstName: "",
    role: "",
  },
};

export const UserModel = {
  _id: 0,
  firstName: "",
  lastName: "",
  email: "",
  password: "",
  role: "",
  roleId: 0,
  roleInfo: "",
  userBadge: "",
  groupKey: "",
  gender: 0,
  civility: "",
  deathDate: "",
  linePhone: "",
  mobilePhone: "",
  rpps: null,
  createdAt: "",
  firstLogin: "",
  verified: false,
  acceptedCookies: false,
  dateAcceptedCookies: "",
  birthDate: "",
  location: {
    street: "",
    zipCode: null,
    city: "",
    country: "",
    streetNumber: "",
  },
};

export const prescriberModel = {
  _id: 0,
  lastUpdate: Date.now(),
  rpps: null,
  user: UserModel,
  speciality: null,
};

export const deviceModel = {
  _id: 0,
  family: { id: 0, label: "" },
  model: "",
};

export const contractModel = {
  _id: 0,
  devices: [deviceModel],
  patientId: 0,
  patient: "",
  prescriberId: 0,
  activity: "",
  endDate: null,
  startDate: null,
  status: {
    id: 1,
    label: "",
  },
  plan: {
    id: 0,
    code: "",
    label: "",
  },
  type: "",
};

export const interventionModel = {
  _id: 0,
  agent: "",
  comment: "",
  patientId: 0,
  patient: "",
  prescriberId: 0,
  contract: 0,
  documents: [],
  date: null,
  catheterType: "",
  insulinType: "",
  sensorType: "",
  type: { id: 0, label: "" },
  status: { id: 0, label: "" },
  metrics: {
    weight: 0,
    size: 0,
    imc: 0,
    tbr: 0,
    tar: 0,
    bas: 0,
    bol: 0,
    HbA1c: 0,
    tir: 0,
    capteur: 0,
    diq: 0,
    cv: 0,
    moyglyc: 0,
  },
};
export const prescriptionModel = {
  _id: 0,
  contract: null,
  endDate: null,
  patientId: 0,
  patient: "",
  prescriberId: 0,
  isDEP: false,
  isPPC: false,
  isPPCMixed: false,
  idToRenew: false,
  isSigned: false,
  signedFile: null,
  signatureDate: null,
  isToRenewUrgently: false,
  patient: null,
  prescribers: [],
  recipientId: null,
  startDate: null,
  status: {
    id: null,
    label: null,
  },
  type: {
    id: null,
    label: null,
  },
};

export const patientModel = {
  _id: 0,
  ipp: null,
  hb1ac: null,
  firstIntervention: interventionModel,
  lastIntervention: interventionModel,
  diabeteType: { id: 0, label: "" },
  risk: null,
  consumables: [],
  tir: null,
  device: "",
  prescriberId: 0,
  prescriber: "",
  contract: contractModel,
  firstContract: contractModel,
  nextFollowUp: null,
  user: UserModel,
  salesManager: "",
  renewal: null,
  nursingConsultant: [],
  nurses: [],
  regionalManager: [],
  socialSecurityNumber: "",
  cv: null,
  contracts: [],
  interventions: [],
  prescriptions: [],
  currentPrescriptions: [],
  renewablePrescriptions: [],
};

export const metricsModel = {
  basal: [],
  bolus: [],
  cg: [],
  cv: [],
  diq: [],
  hba1c: [],
  hba1c_1: [],
  hba1c_2: [],
  hba1c_3: [],
  hba1c_4: [],
  hba1c_5: [],
  imc: [],
  labels: [],
  moyglyc: [],
  size: [],
  tar: [],
  tbr: [],
  tc: [],
  tir: [],
  weight: [],
};
