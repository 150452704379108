import React, { useState, useEffect } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { useSelector, useDispatch } from "react-redux";
import { Loader } from "rsuite";
import DataTable from "react-data-table-component";
import { GoChevronDown } from "react-icons/go";
import { saveAs } from "file-saver";

import Section from "components/Section";
import columns from "./columns";
import customStyles from "./customStyles";
import conditionalRowStyles from "./conditionalRowStyles";

import { userSelector } from "../../redux/user/slice";
import { statisticController } from "../../api/controllers";
import { logout } from "../../redux/user/slice";
import { checkStatus } from "../../services";

const StatisticScreen = () => {
  const [signStat, setSignStat] = useState({ docs: [] });
  const [type, setType] = useState("signature");
  const [isLoading, setIsLoading] = useState(true);
  const [limit, setLimit] = useState(50);
  const [sort, setSort] = useState('{"year":-1, "month":-1}');
  const [currentPage, setCurrentPage] = useState(1);
  const [searchValue, setSearchValue] = useState(null);
  const [filters, setFilters] = useState([
    `limit=${limit}`,
    `page=${currentPage}`,
    `sort=${sort}`,
  ]);

  const userReducer = useSelector(userSelector);
  const dispatch = useDispatch();

  useEffect(() => {
    async function defaultValue() {
      const response = await getStatistic([
        ...filters,
        `and={"type":"signature"}`,
      ]);
      switch (type) {
        case "signature":
          setSignStat(response.data);
          break;
      }
    }
    defaultValue();
  }, []);

  const getStatistic = async (applyFilter = []) => {
    setIsLoading(true);
    const response = await statisticController.getAll(
      applyFilter,
      userReducer.userToken
    );
    if (!checkStatus(response.status)) dispatch(logout());
    setIsLoading(false);
    return response;
  };

  const handleFilterStatisticChange = async (
    value = {
      newLimit: limit,
      newSort: sort,
      newPage: currentPage,
      searchMode: false,
      searchValue: searchValue,
      upType: type,
    }
  ) => {
    let newFilters = filters;
    newFilters.map((filter, index) => {
      if (
        value.newLimit &&
        value.newLimit !== limit &&
        filter === `limit=${limit}`
      ) {
        newFilters[index] = filter = `limit=${value.newLimit}`;
        setLimit(value.newLimit);
      }
      if (value.newSort && filter === `sort=${sort}`) {
        newFilters[index] = filter = `sort=${value.newSort}`;
        setSort(value.newSort);
      }
      if (value.newPage && filter === `page=${currentPage}`) {
        newFilters[index] = filter = `page=${value.newPage}`;
        setCurrentPage(value.newPage);
      }
      if (value.upType && filter.includes("type")) {
        if (value.upType !== "clear") {
          setType(value.upType);
          newFilters[index] = `and={"type":${value.upType}}`;
        } else {
          setType(null);
          newFilters[index] = `nor={"type":""}`;
        }
      }
      return filter;
    });
    setFilters(newFilters);
    const response = await getStatistic(
      filters.concat(filters.prescriberFilter)
    );
    switch (value.upType) {
      case "signature":
        setSignStat(response.data);
        break;
    }
  };

  const getExportStat = async (type) => {
    let response = { data: null };
    response = await statisticController.getExportStat(
      type,
      userReducer.userToken
    );
    if (response.data) {
      console.log(response.headers);
      const fileName =
        response.headers["content-disposition"].split("filename=")[1];
      const fileType = response.headers["content-type"];
      saveAs(new Blob([response.data], { type: fileType }), fileName);
    } else {
      Notification["error"]({
        title: "Extraire connexion prescripteur.",
        duration: 5000,
        description: `Erreur lors de l'extraction des connexions du prescripteur. Merci de contacter un administrateur.`,
      });
    }
  };

  return (
    <Section className="flex flex-col gap-3">
      <div className="flex flex-row justify-between">
        <span className="text-primary font-extrabold text-lg">
          Statistiques
        </span>
      </div>
      <div className="flex flex-col bg-white border border-primary rounded-xl p-3 gap-2">
        <span className="text-primary font-bold">Statistique de signature</span>
        <button
          className="flex items-center gap-1 w-fit px-2 bg-green-600 hover:bg-green-500 p-1 rounded"
          onClick={async () => await getExportStat("signature")}
        >
          <img
            src="/icons/download.svg"
            alt="Statistique de signature"
            width={24}
            height={24}
            className="text-white"
          />
          <p className="text-white font-bold text-sm">
            Exporter les données
          </p>
        </button>
        <div id="dataTable" className="w-full h-full rounded">
          <DataTable
            columns={columns()}
            data={signStat.docs || []}
            customStyles={customStyles}
            conditionalRowStyles={conditionalRowStyles}
            noDataComponent={
              <span className="w-full text-center font-semibold">
                Pas de données
              </span>
            }
            defaultSortFieldId={1}
            defaultSortAsc={false}
            sortIcon={<GoChevronDown color="var(--light)" />}
            striped
            theme="solarized"
            highlightOnHover
            pointerOnHover
            pagination
            paginationPerPage={limit}
            paginationRowsPerPageOptions={[25, 50, 100]}
            progressPending={isLoading}
            progressComponent={<Loader className="w-full text-center" />}
            paginationServer
            paginationDefaultPage={signStat.page}
            paginationServerOptions={{
              persistSelectedOnPageChange: true,
              persistSelectedOnSort: true,
            }}
            paginationTotalRows={signStat.totalDocs}
            onChangePage={async (page) =>
              await handleFilterStatisticChange({
                upType: "signature",
                newPage: page,
              })
            }
            onChangeRowsPerPage={async (newPerPage) =>
              await handleFilterStatisticChange({
                upType: "signature",
                newLimit: newPerPage,
                newPage: 1,
              })
            }
            sortServer
            onSort={async (column, sortDirection) =>
              await handleFilterStatisticChange({
                upType: "signature",
                newSort: `{"${column.sortfield}":${
                  sortDirection === "desc" ? -1 : 1
                }}`,
                newPage: 1,
              })
            }
            dense
            paginationComponentOptions={{
              rowsPerPageText: "Statistique par page:",
              rangeSeparatorText: "sur",
              noRowsPerPage: false,
              selectAllRowsItem: false,
              selectAllRowsItemText: "All",
            }}
          />
        </div>
      </div>
    </Section>
  );
};

export default StatisticScreen;
