import React from "react";
import { Chart, Bar } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";
import {
  dataConfig1,
  dataConfig2,
  dataConfig3,
  dataConfig4,
  config1,
  config2,
  config3,
  config4,
} from "./data";
import TutoSpeechBubble from "components/TutoSpeechBubble";

const TabGraph = ({ isActive = false, metrics = {}, isTutoMode = false }) => {
  Chart.register(ChartDataLabels);
  return (
    <>
      {Object.keys(metrics.labels).length === 0 ? (
        <div className="text-neutral-dark w-full text-center my-5">
          Aucune donnée médicale n'a été renseignée pour ce patient.
        </div>
      ) : null}
      <div
        className={`grid grid-cols-1 lg:grid-cols-2 gap-2 ${
          !isActive ? "is-hidden" : ""
        }`}
      >
        <div>
          <Bar
            data={dataConfig1(metrics)}
            options={config1}
            plugins={ChartDataLabels}
          />
        </div>
        <div>
          <Bar
            data={dataConfig2(metrics)}
            options={config2}
            plugins={ChartDataLabels}
          />
        </div>
        <div>
          <Bar
            data={dataConfig3(metrics)}
            options={config3}
            plugins={ChartDataLabels}
          />
        </div>
        <div>
          <Bar
            data={dataConfig4(metrics)}
            options={config4}
            plugins={ChartDataLabels}
          />
        </div>

        <TutoSpeechBubble
          text="L’onglet « Graphiques données médicales » permet de visualiser les données relevés lors des 6 dernières visites sous forme de graphique."
          position={{ bottom: "16%", left: "40%" }}
          isVisible={false}
          maxWidth={500}
          pointType="default"
          pointPlacement="top-left"
        />
        <TutoSpeechBubble
          text="Les données non pertinentes peuvent être masquées en cliquant sur l’élément à faire disparaitre dans la légende"
          position={{ bottom: "-33%", left: "10%" }}
          isVisible={false}
          pointPlacement="top-left"
          pointType="default"
        />
      </div>
    </>
  );
};

export default TabGraph;
