import { useState } from "react";
import moment from "moment";
import fileManager from "services/fileManager";

const ElementPrescription = ({ prescription, accessToken }) => {
  const [downloadBtnHover, setDownloadBtnHover] = useState(false);
  return (
    <div
      className="flex flex-row justify-between md:10 lg:gap-20"
      id={`prescription-${prescription._id}`}
    >
      <div className="">
        <div className="font-semibold mb-2">
          {prescription.contract?.activity} -{" "}
          {prescription.contract?.plan?.label}
        </div>
        <div>
          <span className="font-semibold">Début :</span>{" "}
          <span>{moment(prescription.startDate).format("DD/MM/YYYY")}</span>
        </div>
        <div>
          <span className="font-semibold">Fin :</span>{" "}
          <span>{moment(prescription.endDate).format("DD/MM/YYYY")}</span>
        </div>
      </div>
      {prescription.isSigned && prescription.status?.id === "Accepted" ? (
        <div className="flex flex-col justify-start items-end gap-4">
          <p className="font-light text-xs border border-validate-dark bg-validate p-1 px-2 rounded-full">
            Signée
          </p>
          <div className="column is-narrow w-fit">
            <button
              className="flex justify-between gap-1 items-center border border-dark px-3 py-1 w-full font-semibold text-dark rounded hover:bg-dark hover:text-white"
              onMouseEnter={() => setDownloadBtnHover(true)}
              onMouseLeave={() => setDownloadBtnHover(false)}
              onClick={async () =>
                await fileManager.downloadOrdo(prescription._id, accessToken)
              }
            >
              <p>Téléchager</p>
              <img
                src={`${
                  !downloadBtnHover
                    ? "/icons/renewal-download.svg"
                    : "/icons/renewal-download-alt.svg"
                }`}
                className="h-5 w-5"
              />
            </button>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default ElementPrescription;
