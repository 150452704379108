import { Link } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import moment from "moment";
import ClassNames from "classnames";

import TutoSpeechBubble from "components/TutoSpeechBubble";
import { fileManager } from "services";
import { checkTypeDocDemo } from "utils";

const columns = ({ isTutoMode, access_token }) => [
  {
    name: (
      <>
        Statut Ordo.
        <img
          src="/icons/renewal.svg"
          className="w-8 h-8 p-1"
          alt="download"
          style={{
            position: "absolute",
            zIndex: 50,
            bottom: -45,
            visibility: isTutoMode ? "visible" : "hidden",
          }}
        />
        <TutoSpeechBubble
          text="Cet icône permet d'identifier les patients ayant au moins une prescription en renouvellement."
          position={{ top: "250%", left: "70%" }}
          isVisible={isTutoMode}
          pointType="default"
          pointPlacement="top-left"
        />
        <img
          src="/icons/renewal-late.svg"
          className="w-8 h-8 p-1"
          alt="download"
          style={{
            position: "absolute",
            zIndex: 50,
            bottom: -210,
            visibility: isTutoMode ? "visible" : "hidden",
          }}
        />
        <TutoSpeechBubble
          text="Cet icône permet d'identifier les patients ayant au moins une prescription en retard de renouvellement."
          position={{ top: "615%", left: "70%" }}
          isVisible={isTutoMode}
          pointType="default"
          pointPlacement="top-left"
        />
      </>
    ),
    sortable: false,
    width: "70px",
    cell: (row) => (
      <div className="flex gap-1 items-center justify-center w-full">
        {row.renewal && row.renewal.hasRenewal ? (
          <>
            <img
              src={`/icons/renewal${row.renewal.hasLate ? "-late" : ""}.svg`}
              className={ClassNames("h-5 w-5", isTutoMode ? "blur-lg" : "")}
              tooltipId={`renewal_${row._id}`}
              data-tip={
                !row.renewal.hasLate
                  ? "Ordonnance à renouveler"
                  : "Ordonnance en retard "
              }
            />
            <ReactTooltip
              _id={`renewal_${row._id}`}
              place="left"
              effect="solid"
            />
          </>
        ) : null}
      </div>
    ),
  },
  {
    name: "Patient",
    selector: (row) => `${row.fullName}`,
    sortable: true,
    sortfield: "lastName",
    width: "200px",
    cell: (row) => (
      <Link
        to={{ pathname: `patients/${row._id}`, state: { patient: row } }}
        target="_blank"
        className={ClassNames([
          "text-primary font-semibold hover:text-light focus:text-light",
          isTutoMode ? "blur" : null,
        ])}
      >
        <span>{`${row.lastName} ${row.firstName}`}</span>
      </Link>
    ),
  },
  {
    name: "Prescripteur",
    selector: (row) => `${row.prescriber}`,
    sortable: true,
    sortfield: "prescriber",
    width: "150px",
    cell: (row) => (
      <span
        data-tag="allowRowEvents"
        className={ClassNames([isTutoMode ? "blur" : null])}
      >
        {row.prescriber}
      </span>
    ),
  },
  {
    name: (
      <>
        Installation
        <TutoSpeechBubble
          text="Toutes les colonnes peuvent être triées de A>Z ou Z>A."
          position={{ bottom: "-150%", left: "80%" }}
          isVisible={isTutoMode}
          pointType="default"
          pointPlacement="top-left"
        />
      </>
    ),
    selector: (row) => `${row.firstContract.startDate}`,
    sortable: true,
    sortfield: "firstContract.startDate",
    width: "118px",
    cell: (row) => (
      <span
        data-tag="allowRowEvents"
        className={ClassNames([isTutoMode ? "blur" : null])}
      >
        {row.firstContract?.startDate
          ? moment(row.firstContract.startDate).format("DD/MM/YYYY")
          : ""}
      </span>
    ),
  },
  {
    name: "Dernière int.",
    selector: (row) =>
      `${
        row.lastIntervention && moment.isDate(row.lastIntervention.date)
          ? moment(row.lastIntervention.date).format("DD/MM/YYYY")
          : null
      }`,
    sortable: true,
    sortfield: "lastIntervention.date",
    width: "127px",
    cell: (row) => (
      <span
        data-tag="allowRowEvents"
        className={ClassNames([isTutoMode ? "blur" : null])}
      >
        {row.lastIntervention
          ? moment(row.lastIntervention.date).format("DD/MM/YYYY")
          : null}
      </span>
    ),
  } /*
  {
    name: "Prochain suivi",
    selector: (row) => null,
    sortable: true,
    width: "120px",
    cell: (row) => <span data-tag="allowRowEvents">-</span>,
  },*/,
  {
    name: "HbA1c (%)",
    selector: (row) =>
      `${
        row.lastIntervention && row.lastIntervention.metrics
          ? row.lastIntervention.metrics.HbA1c
          : null
      }`,
    sortable: true,
    sortfield: "lastIntervention.metrics.HbA1c",
    width: "114px",
    cell: (row) => (
      <span
        data-tag="allowRowEvents"
        className={ClassNames([isTutoMode ? "blur" : null])}
      >
        {row.lastIntervention && row.lastIntervention.metrics
          ? row.lastIntervention.metrics.HbA1c
          : null}
      </span>
    ),
  },
  {
    name: "Pompe",
    selector: (row) => `${row.pump ? row.pump.model : null}`,
    sortable: true,
    sortfield: "pump.model",
    cell: (row) => (
      <span
        data-tag="allowRowEvents"
        className={ClassNames([isTutoMode ? "blur" : null])}
      >
        {row.pump && typeof row.pump.model === "string"
          ? row.pump.model.replace("POMPE ", "")
          : null}
      </span>
    ),
  },
  {
    name: "Capteur",
    selector: (row) =>
      `${
        row.lastIntervention && row.lastIntervention.sensorType
          ? row.lastIntervention.sensorType
          : null
      }`,
    sortable: true,
    sortfield: "lastIntervention.sensorType",
    cell: (row) => (
      <span
        data-tag="allowRowEvents"
        className={ClassNames([isTutoMode ? "blur" : null])}
      >
        {" "}
        {row.lastIntervention && row.lastIntervention.sensorType
          ? row.lastIntervention.sensorType.replaceAll('"', "")
          : null}
      </span>
    ),
  },
  {
    name: "TIR (%)",
    selector: (row) =>
      `${
        row.lastIntervention && row.lastIntervention.metrics
          ? row.lastIntervention.metrics.tir
          : null
      }`,
    sortable: true,
    sortfield: "lastIntervention.metrics.tir",
    width: "95px",
    cell: (row) => (
      <span
        data-tag="allowRowEvents"
        className={ClassNames([isTutoMode ? "blur" : null])}
      >
        {" "}
        {row.lastIntervention && row.lastIntervention.metrics
          ? row.lastIntervention.metrics.tir
          : null}
      </span>
    ),
  } /*
  {
    name: "CV",
    selector: (row) =>
      `${
        row.lastIntervention && row.lastIntervention.metrics
          ? row.lastIntervention.metrics.cv
          : null
      }`,
    sortable: true,
    sortfield: "lastIntervention.metrics.cv",
    width: "90px",
    cell: (row) => (
      <span data-tag="allowRowEvents">
        {" "}
        {row.lastIntervention && row.lastIntervention.metrics
          ? row.lastIntervention.metrics.cv
          : null}
      </span>
    ),
  },*/,
  {
    name: (
      <span>
        CR non lu
        <img
          src="/icons/download-courrier.svg"
          className="h-8 w-8 p-1 ml-5"
          alt="download"
          style={{
            position: "absolute",
            zIndex: 50,
            bottom: -45,
            visibility: isTutoMode ? "visible" : "hidden",
          }}
        />
        <TutoSpeechBubble
          text="Cet icône permet d’accéder directement au dernier compte-rendu non consulté du patient concerné."
          position={{ top: "270%", right: "65%" }}
          isVisible={isTutoMode}
          pointType="default"
          pointPlacement="top-right"
        />
      </span>
    ),
    sortable: false,
    sortField: null,
    width: "88px",
    cell: (row) => {
      if (
        Array.isArray(row.lastIntervention?.documents) &&
        row.lastIntervention?.documents?.length > 0
      ) {
        const filterDocs = row.lastIntervention.documents
          .filter((doc) => doc.importType === "Courrier")
          .sort((prevDoc, currentDoc) => {
            return moment(currentDoc.date._d).isAfter(prevDoc.date._d);
          });
        const doc =
          filterDocs.length > 0 && !filterDocs[0].firstReader
            ? filterDocs[0]
            : null;
        if (doc) {
          if (doc.extension === "pdf") {
            return (
              <Link
                key={`Cdoc_${doc.id}`}
                to={`/file/intervention/${row.lastIntervention._id}?doc=${
                  doc.id
                }${
                  process.env.REACT_APP_DEMO ? "&type=" + doc.importType : ""
                }`}
                target="_blank"
                data-tip={
                  process.env.REACT_APP_DEMO
                    ? checkTypeDocDemo(doc.importType)
                    : doc.name
                }
                className={ClassNames([
                  "flex flex-1 justify-center",
                  isTutoMode ? "blur" : "",
                ])}
              >
                <img
                  src="/icons/download-courrier.svg"
                  className="p-1"
                  alt="download"
                  width={33}
                  height={33}
                  tooltipId={`doc_${doc.id}`}
                  data-tip={
                    process.env.REACT_APP_DEMO
                      ? checkTypeDocDemo(doc.importType)
                      : doc.name
                  }
                />
                <ReactTooltip
                  _id={`doc_${doc.id}`}
                  place="left"
                  effect="solid"
                />
              </Link>
            );
          } else {
            return (
              <div
                key={`Cdoc_${doc.id}`}
                data-tip={
                  process.env.REACT_APP_DEMO
                    ? checkTypeDocDemo(doc.importType)
                    : doc.name
                }
                className={ClassNames([
                  "flex flex-1 justify-center cursor-pointer",
                  isTutoMode ? "blur" : "",
                ])}
              >
                <img
                  src="/icons/download-courrier.svg"
                  className="p-1"
                  alt="download"
                  width={33}
                  height={33}
                  tooltipId={`doc_${doc.id}`}
                  data-tip={
                    process.env.REACT_APP_DEMO
                      ? checkTypeDocDemo(doc.importType)
                      : doc.name
                  }
                  onClick={async () => {
                    if (!process.env.REACT_APP_DEMO) {
                      await fileManager.download(
                        "intervention",
                        row.lastIntervention._id,
                        doc.id,
                        access_token
                      );
                    } else {
                      window.open(
                        `/file/intervention/${row.lastIntervention._id}?doc=${
                          doc.id
                        }${
                          process.env.REACT_APP_DEMO
                            ? "&type=" + doc.importType
                            : ""
                        }`,
                        "_blank"
                      );
                    }
                  }}
                />
                <ReactTooltip
                  _id={`doc_${doc.id}`}
                  place="left"
                  effect="solid"
                />
              </div>
            );
          }
        } else {
          return null;
        }
      }
    },
  },
];

export default columns;
