import { useState } from "react";
import Modal from "react-modal";

import "./Modal.css";

const defaultCustomStyles = {
  overlay: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(73, 80, 86, 0.40)",
  },
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    padding: "0",
    marginRight: "-50%",
    maxHeight: "95vh",
    maxWidth: "95vw",
    marginTop: 0,
    transform: "translate(-50%, -50%)",
    borderRadius: 10,
  },
};

const ModalComponent = ({
  id,
  Content,
  OpenComponent,
  closeHandle,
  afterOpenModal,
  customStyles = defaultCustomStyles,
}) => {
  const [modalIsOpen, setIsOpen] = useState(false);
  Modal.setAppElement("html");

  function openModal() {
    setIsOpen(true);
  }

  const closeModal = async () => {
    if (closeHandle) await closeHandle();
    setIsOpen(false);
  };

  return (
    <span>
      <OpenComponent onClick={openModal} />
      <Modal
        id={id}
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
      >
        {Content({ onCloseModal: async () => await closeModal() })}
      </Modal>
    </span>
  );
};

export default ModalComponent;
