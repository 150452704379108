import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import validator from "validator";
import { Notification, Loader } from "rsuite";

import { authController } from "../../../api/controllers";
import { history } from "../../../services";

import { BsChevronLeft } from "react-icons/bs";
import AuthSection from "components/AuthSection";
import Input from "components/Input";

const ForgotPasswordScreen = () => {
  const [email, setEmail] = useState(null);
  const [errEmailMsg, setErrEmailMsg] = useState(null);
  const [succesEmailMsg, setSuccessEmailMsg] = useState(null);
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const { handleSubmit } = useForm();

  useEffect(() => {
    async function defaultValue() {}
    defaultValue();
  }, []);

  function isEmail(val) {
    if (validator.isEmail(val)) {
      setEmail(val);
      setErrEmailMsg(null);
      setButtonDisabled(false);
    } else {
      setEmail(null);
      setButtonDisabled(true);
      setErrEmailMsg("email invalide.");
    }
  }

  const handleForgotPassword = async () => {
    if (email) {
      setIsLoading(true);
      const response = await authController.forgotPassword({ email });
      setButtonDisabled(true);
      setIsLoading(false);
      console.log(response);
      if (response.data?.msg === "RESET_EMAIL_SENT") {
        history.push("/?notif=forgot_success");
      } else {
        Notification["error"]({
          title: "Réinitialisation mot de passe",
          duration: 5000,
          description: "Erreur de l'envoie du mail de réinitialisation.",
        });
        setButtonDisabled(false);
      }
    } else {
      setSuccessEmailMsg(null);
    }
  };

  if (isLoading) return <Loader />;

  return (
    <AuthSection>
      <form
        className="flex flex-col gap-2"
        onSubmit={handleSubmit(handleForgotPassword)}
      >
        <span>
          Veuillez saisir dans le champ ci-dessous votre adresse email lié à
          votre compte. Un lien vous sera envoyé pour réinitiliser votre mot de
          passe.
        </span>
        <Input
          type="text"
          size="sm"
          label="Adresse email"
          className={errEmailMsg ? "border border-red-500" : null}
          placeholder="Veuillez saisir votre adresse email"
          onChange={isEmail}
        />
        <div className="text-red-500 text-xs mb-2">{errEmailMsg}</div>
        <button
          className="rounded border border-primary py-1 px-10 p w-fit mx-auto text-primary hover:bg-primary hover:text-white my-2"
          disabled={buttonDisabled}
        >
          Envoyer
        </button>
        <a
          href="/"
          className="text-sm text-blue-800 underline w-fit flex flex-row items-center space-x-1"
        >
          <BsChevronLeft />
          <span className="underline">Retour</span>
        </a>
      </form>
    </AuthSection>
  );
};

export default ForgotPasswordScreen;
