import { createAsyncThunk } from "@reduxjs/toolkit";
import { Notification } from "rsuite";

import { authController } from "../../api/controllers";

export const login = createAsyncThunk(
  "users/login",
  async ({ email, password, acceptedCookies }, thunkAPI) => {
    let user = null,
      userToken = null,
      roleInfo = null,
      errors = [],
      isLoggedIn = false;
    const response = await authController.login({
      email,
      password,
      acceptedCookies,
    });
    let hasAccess = false;
    let errorMsg = "";
    if (response.status === 200 && !response.errors) {
      if (process.env.REACT_APP_IS_PATIENT) {
        if (response.data.user?.role === "patient") {
          hasAccess = true;
        } else {
          errorMsg = "Vous devriez vous connecter à l'espace prescripteur.";
        }
      } else {
        if (response.data.user?.role !== "patient") {
          hasAccess = true;
        } else {
          errorMsg = "Vous devriez vous connecter à l'espace patient.";
        }
      }
      if (hasAccess) {
        user = response.data.user;
        userToken = response.data.token;
        roleInfo = response.data.roleInfo;
        isLoggedIn = true;
        errors = [];
      } else {
        errors = [{ msg: errorMsg }];
        Notification["error"]({
          title: "Erreur detectée",
          duration: 5000,
          description: errorMsg,
        });
      }
    } else {
      errors = response.errors;
    }
    return {
      user,
      userToken,
      roleInfo,
      isLoggedIn,
      status: response.status,
      errors,
    };
  }
);

export const checkToken = createAsyncThunk(
  "users/checkToken",
  async ({ access_token }, thunkAPI) => {
    const response = await authController.checkToken(access_token);
    return !response <= 200
      ? {
          user: null,
          userToken: null,
          isLoggedIn: false,
          status: null,
          errors: null,
        }
      : null;
  }
);
