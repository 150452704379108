import { Notification } from "rsuite";

export const axiosErrorManager = (error) => {
  let errors = [];
  if (error.response) {
    errors = error.response.data
      ? error.response.data.errors
        ? Array.isArray(error.response.data.errors.msg)
          ? error.response.data.errors.msg
          : [{ msg: error.response.data.errors.msg }]
        : Array.isArray(error.response.data)
        ? error.response.data
        : [{ msg: error.response.data }]
      : [];
  } else {
    errors.push({ msg: "503 Service Unavailable" });
  }
  errors.map((err) => {
    console.log(err);
    Notification["error"]({
      title: "Erreur detectée",
      duration: 5000,
      description: typeof err.msg === "string" ? err.msg : "Erreur de chargement.",
    });
    return err;
  });
  return {
    status: error.response ? error.response.status : 503,
    statusText: error.response
      ? error.response.statusText
      : "503 Service Unavailable",
    data: null,
    errors,
  };
};
