const codes = [
  "AT",
  "BFMED",
  "BFMEDG4",
  "CANL",
  "CADXG6",
  "CADXG6S",
  "ST",
  "STD",
  "STMT",
  "SPBF",
  "SPST",
  "SPSTD",
  "SPAT",
  "BFCAMAPS",
  "BFTANDEM",
];
const columns = () => {
  const data = [
    {
      name: "Année",
      selector: (row) => `${row.year}`,
      sortable: true,
      sortfield: "year",
      minWidth: "90px",
      cell: (row) => <span data-tag="allowRowEvents">{row.year}</span>,
    },
    {
      name: "Mois",
      selector: (row) => `${row.year}`,
      sortable: true,
      sortfield: "month",
      minWidth: "0px",
      width: "100px",
      cell: (row) => <span data-tag="allowRowEvents">{row.monthString}</span>,
    },
    {
      name: "TOTAL",
      selector: (row) => `${row.total}`,
      sortable: true,
      sortfield: "total",
      minWidth: "0px",
      width: "100px",
      cell: (row) => <span data-tag="allowRowEvents">{row.total}</span>,
    },
  ];

  for (const code of codes) {
    data.push({
      name: <span className="">{code}</span>,
      selector: (row) => `${row["count" + code]}`,
      sortable: false,
      sortfield: "count" + code,
      minWidth: "87,5px",
      cell: (row) => (
        <span data-tag="allowRowEvents" className="text-xs">
          {row["count" + code]}
        </span>
      ),
    });
  }
  return data;
};

export default columns;
