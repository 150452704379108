import React, { useState } from "react";
import { TagPicker, Loader, Tag } from "rsuite";
import { FaUserAlt, FaUserMd, FaUsers } from "react-icons/fa";

const TagPickerComponent = ({
  label,
  onSearch,
  onChanged,
  onClose,
  className,
  placeholder,
  value,
}) => {
  const [cacheData, setCacheData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [items, setItems] = useState([]);

  const handleSelect = async (values = [], item, event) => {
    let selectedList = cacheData.filter((data) => values.includes(data.value));
    if (!selectedList.includes(item.value)) selectedList.push(item);
    setCacheData(selectedList);
  };

  const handleSearch = async (word) => {
    if (!word) {
      return;
    }
    setLoading(true);
    const result = await onSearch(word);
    result.items = result.items ? result.items : [];
    setLoading(result.loading);
    setItems(result.items);
  };

  const handleChange = async (result = []) => {
    await onChanged(result);
  };

  const getlabelGroup = (label) => {
    switch (label) {
      case "prescriber":
        return "Prescripteurs";
      case "patient":
        return "Patients";
      case "admin":
        return "Administrateurs";
      case "superadmin":
        return "Super administrateurs";
      default:
        return "Patients";
    }
  };

  return (
    <div className="flex flex-col gap-1">
      {label ? <label className="text-xs font-semibold">{label}</label> : null}
      <TagPicker
        data={items}
        cacheData={cacheData}
        groupBy="role"
        cleanable={false}
        value={value}
        placeholder={placeholder}
        size="sm"
        className={className}
        block
        onChange={handleChange}
        onSearch={handleSearch}
        onSelect={handleSelect}
        renderMenu={(menu) => {
          if (loading) {
            return (
              <p style={{ padding: 4, color: "#999", textAlign: "center" }}>
                <Loader /> Chargement...
              </p>
            );
          }
          return menu;
        }}
        renderMenuItem={(label, item) => {
          return (
            <span className="flex flex-row gap-1 items-center">
              {item.role === "prescriber" ? (
                <FaUserMd color="var(--primary)" />
              ) : (
                <FaUserAlt color="var(--primary)" />
              )}
              {label}
            </span>
          );
        }}
        renderMenuGroup={(label, item) => {
          return (
            <span className="flex flex-row gap-1 items-center">
              <FaUsers color="var(--primary)" /> {getlabelGroup(label)} - (
              {item.children.length})
            </span>
          );
        }}
        renderValue={(values) => {
          return values.map((tag, index) => (
            <Tag key={index} closable onClose={async () => onClose(tag)}>
              <span className="flex flex-row gap-1 items-center">
                <FaUserMd color="var(--primary)" /> {tag}
              </span>
            </Tag>
          ));
        }}
      />
    </div>
  );
};

export default TagPickerComponent;
