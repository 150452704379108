import { saveAs } from "file-saver";
import {
  interventionController,
  prescriptionController,
} from "../api/controllers";

const fileManager = {
  download: async (type, typeId, docId, accessToken) => {
    let response = { data: null };
    switch (type) {
      case "intervention":
        response = await interventionController.getDocument(
          typeId,
          docId,
          accessToken
        );
        break;
      default:
        return (response.data = null);
    }
    if (response.data) {
      const fileName = response.headers["content-disposition"].split('"')[1];
      const fileType = response.headers["content-type"];
      saveAs(new Blob([response.data], { type: fileType }), fileName);
    }
  },
  downloadOrdo: async (prescriptionId, accessToken) => {
    let response = { data: null };
    response = await prescriptionController.getDocument(
      prescriptionId,
      accessToken
    );
    if (response.data) {
      const fileName = response.headers["content-disposition"].split('"')[1];
      const fileType = response.headers["content-type"];
      saveAs(new Blob([response.data], { type: fileType }), fileName);
    }
  },
};

export default fileManager;
