const AuthSection = ({ children, className, noCard, noLogo }) => {
  return (
    <div className="relative bg-white">
      <div className="flex flex-row justify-between absolute z-0 h-screen w-screen">
        <div className="w-0 md:w-0 lg:w-[60%] h-[67.5%] lg:pl-5">
          <div className="bg-secondary h-full w-full"></div>
        </div>
        <div className="w-full lg:w-[40%] h-screen flex flex-col justify-end">
          <div className="w-[35%] h-[35%] lg:h-[25%] bg-primary flex justify-center p-2 ml-auto">
            <img src="auth/group.svg" alt="group" className="" />
          </div>
          <div className="w-[65%] h-[40%] bg-light flex items-end">
            <img src="auth/perso.svg" alt="perso" className="mx-auto" />
          </div>
        </div>
      </div>

      <section className="flex items-start justify-center h-screen w-screen relative px-2 pt-5">
        {!noLogo ? (
          <div className="invisible lg:visible absolute top-3 left-3 md:left-20">
            <img
              src="/logo-back-white.png"
              alt="logo"
              className="w-[130px] h-[56px] lg:w-[230px] lg:h-[126px]"
            />
          </div>
        ) : null}
        {noCard ? (
          <div className={className}>{children}</div>
        ) : (
          <div className="flex flex-col gap-4 bg-white rounded-lg shadow-xl p-10 max-w-[590px] mt-7">
            <span className="flex flex-col justify-center text-center text-2xl font-bold text-primary gap-0">
              <span>Bienvenue sur le portail</span>
              {process.env.REACT_APP_IS_PATIENT ? (
                <span>patient ISIS DIABÈTE</span>
              ) : (
                <span>prescripteur ISIS DIABÈTE</span>
              )}
            </span>

            <div className={className}>{children}</div>
          </div>
        )}
      </section>
    </div>
  );
};

export default AuthSection;
