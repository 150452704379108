import { axiosManager, sanitizer } from "../../services";
import { apiCredentials } from "../apiCredentials";

const authController = {
  checkToken: async (access_token) => {
    const config = {
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
    };
    return await axiosManager.get(apiCredentials.url + "/checkToken", config);
  },
  login: async (data) => {
    data = sanitizer(data);
    return await axiosManager.post(apiCredentials.url + "/login", data);
  },
  firstLogin: async (data) => {
    data = sanitizer(data);
    return await axiosManager.post(apiCredentials.url + "/firstLogin", data);
  },
  forgotPassword: async (data) => {
    data = sanitizer(data);
    console.log("data", data);
    return await axiosManager.post(apiCredentials.url + "/forgot", data);
  },
  resetPassword: async (data) => {
    data = sanitizer(data);
    return await axiosManager.post(apiCredentials.url + "/reset", data);
  },
  verify: async (data) => {
    data = sanitizer(data);
    return await axiosManager.post(apiCredentials.url + "/verify/", data);
  },
};

export default authController;
