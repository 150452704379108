import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import validator from "validator";
import { useForm } from "react-hook-form";
import AuthSection from "components/AuthSection";
import Banner from "components/Banner";
import { Loader, Notification } from "rsuite";
import { userSelector } from "../../../redux/user/slice";
import Input from "components/Input";
import { login } from "../../../redux/user/actions";
import { authController } from "api/controllers";

import classNames from "classnames";

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const LoginScreen = () => {
  const dispatch = useDispatch();
  const userReducer = useSelector(userSelector);
  const [email, setEmail] = useState(null);
  const [password, setPassword] = useState(null);
  const [errPassword, setErrPassword] = useState(null);
  const [loading, setLoading] = useState(null);
  const query = useQuery();
  const { handleSubmit } = useForm();
  const [visiblePassword, setVisiblePassword] = useState(false);
  const errPasswordMessage =
    "Le mot de passe doit contenir au moins 8 caractères dont 1 majuscule, 1 minuscule, 1 nombre, 1 symbole.";

  useEffect(() => {
    async function defaultValue() {
      if (query.get("notif")) {
        let description = "";
        switch (query.get("notif")) {
          case "forgot_success":
            description =
              "Un email de réinitialisation vous a été envoyé. Veuillez vérifier votre boîte de reception.";
            break;
          case "reset_success":
            description = "Votre mot de passe a été modifié avec succés.";
            break;
          case "verify_success":
            description =
              "Votre compte a été validé avec succés. Vous avez maintenant accés au site.";
        }
        Notification["success"]({
          title: "Authentification",
          duration: 5000,
          description,
        });
      }
    }
    defaultValue();
  }, []);

  const loginHandle = () => {
    setLoading(true);
    password
      ? dispatch(login({ email, password, acceptedCookies: true }))
      : setErrPassword(errPasswordMessage);
    setLoading(false);
  };
  const isValidePassword = (val) => {
    if (
      validator.isStrongPassword(val, {
        minLength: 8,
        minLowercase: 1,
        minUppercase: 1,
        minNumbers: 1,
        minSymbols: 1,
      })
    ) {
      setPassword(val);
      setErrPassword(null);
      return true;
    } else {
      setPassword(null);
      setErrPassword(errPasswordMessage);
      return false;
    }
  };

  const firstLogin = async () => {
    setLoading(true);
    if (email && validator.isEmail(email)) {
      const response = await authController.firstLogin({ email });
      console.log(response);
      if (response.status === 200 || response.status === 201) {
        Notification["success"]({
          title: "Première connexion",
          duration: 5000,
          description:
            "Un mail de première connexion vous a été envoyé. Veuillez vérifier votre adresse mail et cliquer sur le lien pour finaliser votre inscription.",
        });
      }
    } else {
      Notification["error"]({
        title: "Première connexion",
        duration: 5000,
        description: "Veuillez saisir une adresse mail valide.",
      });
    }
    setLoading(false);
  };

  const handleVisblePassword = () => {
    setVisiblePassword(!visiblePassword);
  };

  return (
    <AuthSection className="flex flex-col gap-3">
      <form
        className="flex flex-col gap-3"
        onSubmit={handleSubmit(loginHandle)}
        method="POST"
      >
        <Input
          type="email"
          name="email"
          label="Email"
          required={true}
          onChange={setEmail}
          placeholder="Veuillez saisir votre adresse mail"
        />
        <div>
          <Input
            type={!visiblePassword ? "password" : "text"}
            name="password"
            required={true}
            isPassword={true}
            label="Mot de passe"
            onChange={isValidePassword}
            visiblePassword={visiblePassword}
            handleVisblePassword={handleVisblePassword}
            className={errPassword ? "border border-red-500" : null}
            placeholder="Veuillez saisir votre mot de passe"
          />
          <div
            className={classNames(
              "text-red-500 text-xs",
              errPassword ? "d-block" : "hidden"
            )}
          >
            {errPassword}
          </div>
        </div>

        <a href="/forgot" className="text-xs text-blue-800 underline w-fit">
          <span className="underline">Mot de passe oublié</span>
        </a>
        <button
          type="submit"
          className="rounded border border-primary py-1 px-10 p w-fit mx-auto text-primary hover:bg-primary hover:text-white my-2"
        >
          {loading ? <Loader /> : "Connexion"}
        </button>
        <div>
          C'est votre première connexion ? Renseignez-votre email et{" "}
          <span
            className="text-blue-800 cursor-pointer hover:opacity-70"
            onClick={firstLogin}
          >
            Cliquez ici pour valider votre compte.
          </span>
        </div>
      </form>
      <Banner />
    </AuthSection>
  );
};

export default LoginScreen;
