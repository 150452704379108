import { Input, InputGroup } from "rsuite";
import EyeIcon from "@rsuite/icons/legacy/Eye";
import EyeSlashIcon from "@rsuite/icons/legacy/EyeSlash";

const InputComponent = ({
  label,
  type,
  size,
  icon,
  name,
  required,
  onChange,
  className,
  placeholder,
  defaultValue,
  disabled = false,
  isPassword = false,
  visiblePassword,
  handleVisblePassword,
}) => (
  <div className="flex flex-col gap-1">
    {label ? (
      <label
        className="text-xs font-semibold"
        style={{ fontFamily: "PoppinsSemiBold" }}
      >
        {label}
      </label>
    ) : null}
    <InputGroup inside>
      {icon ? (
        <InputGroup.Addon>
          <icon />
        </InputGroup.Addon>
      ) : null}

      <Input
        type={type ? type : "text"}
        onChange={onChange}
        className={className}
        placeholder={placeholder}
        defaultValue={defaultValue}
        disabled={disabled}
        size={size}
        required={required}
        name={name}
      />
      {isPassword ? (
        <InputGroup.Button onClick={handleVisblePassword} size="sm" className="!h-[30px]">
          {visiblePassword ? <EyeIcon /> : <EyeSlashIcon />}
        </InputGroup.Button>
      ) : null}
    </InputGroup>
  </div>
);

export default InputComponent;
