import { createSlice } from "@reduxjs/toolkit";
import { login, checkToken } from "./actions";

export const userSlice = createSlice({
  name: "user",
  initialState: {
    user: null,
    userToken: null,
    roleInfo: null,
    isFetching: false,
    isSuccess: false,
    isLoggedIn: false,
    errors: [],
  },
  reducers: {
    clearState: (state) => {
      state.isError = false;
      state.isSuccess = false;
      state.isFetching = false;

      return state;
    },
    logout: (state) => {
      state.user = null;
      state.userToken = null;
      state.roleInfo = null;
      state.isLoggedIn = false;
      state.isFetching = false;
      state.isSuccess = false;
      state.errors = [];
      return state;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(login.fulfilled, (state, action) => {
      state.user = action.payload.user;
      state.userToken = action.payload.userToken;
      state.roleInfo = action.payload.roleInfo;
      state.isLoggedIn = action.payload.isLoggedIn;
      state.isFetching = false;
      state.isSuccess = true;
      state.errors = action.payload.errors;
    });
    builder.addCase(login.rejected, (state, action) => {
      state.isFetching = false;
      state.isLoggedIn = false;
      state.errors = action.payload.errors;
    });
    builder.addCase(login.pending, (state) => {
      state.isLoggedIn = false;
      state.isFetching = true;
    });
    builder.addCase(checkToken.fulfilled, (state, action) => {
      state.user = action.user;
      state.userToken = action.userToken;
      state.roleInfo = action.payload.roleInfo;
      state.isLoggedIn = action.isLoggedIn;
      state.isFetching = false;
      state.isSuccess = false;
    });
    builder.addCase(checkToken.rejected, (state, action) => {
      state.isFetching = false;
      state.isLoggedIn = false;
      state.errors = [{ msg: "Unauthorized" }];
    });
    builder.addCase(checkToken.pending, (state) => {
      state.isLoggedIn = false;
      state.isFetching = true;
    });
  },
});

export const { clearState, logout } = userSlice.actions;

export const userSelector = (state) => state.userReducer;

export default userSlice.reducer;
