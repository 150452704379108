import { axiosManager, fileManager } from "../../services";
import { apiCredentials } from "../apiCredentials";

const endpoint = apiCredentials.url + "/patients/";
const patientController = {
  getOne: (patientId, access_token) => {
    const config = {
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
    };
    return axiosManager.get(endpoint + patientId, config);
  },
  getAll: async (filters = [], access_token) => {
    const config = {
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
    };
    let params = "";
    filters.map((filter) => (params += `&${filter}`));
    return await axiosManager.get(endpoint + "?" + params, config);
  },
  downloadDocument: async (documentId, access_token) => {
    const config = {
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
    };
    const document = await axiosManager.get(
      endpoint + "document/" + documentId,
      config
    );
    if (document)
      await fileManager.downloadBase64(
        document.data.name,
        document.data.content,
        document.data.extension
      );
    return null;
  },
};

export default patientController;
