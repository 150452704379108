import axios from "axios";
import { axiosErrorManager } from "./axiosErrorManager";

const axiosManager = {
  get: async (endpoint, config = {}) => {
    return await axios
      .get(endpoint, config)
      .then((response) => ({
        status: response.status,
        data: response.data,
        headers: response.headers,
      }))
      .catch((error) => axiosErrorManager(error));
  },
  post: async (endpoint, data, config = {}) => {
    return await axios
      .post(endpoint, data, config)
      .then((response) => ({
        status: response.status,
        data: response.data,
        headers: response.headers,
      }))
      .catch((error) => axiosErrorManager(error));
  },

  update: async (endpoint, data, config = {}) => {
    return await axios
      .patch(endpoint, data, config)
      .then((response) => ({
        status: response.status,
        data: response.data,
        headers: response.headers,
      }))
      .catch((error) => axiosErrorManager(error));
  },
  delete: async (endpoint, config = {}) => {
    return await axios
      .delete(endpoint, config)
      .then((response) => ({
        status: response.status,
        data: response.data,
        headers: response.headers,
      }))
      .catch((error) => axiosErrorManager(error));
  },
  file: async (endpoint, config = {}) => {
    return await axios
      .get(endpoint, config)
      .catch((error) => axiosErrorManager(error));
  },
};

export default axiosManager;
