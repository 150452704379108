import React, { useState, useEffect, useContext } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { useSelector, useDispatch } from "react-redux";
import {
  SelectPicker,
  CheckPicker,
  DatePicker,
  DateRangePicker,
  Loader,
  InputNumber,
  Toggle,
  Checkbox,
} from "rsuite";
import { GoChevronDown } from "react-icons/go";
import { GoSettings } from "react-icons/go";
import DataTable from "react-data-table-component";
import moment from "moment";
import classNames from "classnames";

import { MenuContext } from "../../contexts/MenuContext";

import TagPicker from "components/TagPicker";
import { userSelector } from "../../redux/user/slice";
import { ButtonIcon } from "../../components/ButtonIcon";
import {
  patientController,
  prescriberController,
  deviceController,
  prescriptionController,
} from "../../api/controllers";
import { logout } from "../../redux/user/slice";
import { checkStatus } from "../../services";
import { checkNumber } from "../../utils";
import { Locale, tirList, diabeteTypes } from "./constants";
import TutoSpeechBubble from "../../components/TutoSpeechBubble";

import Section from "components/Section";
import columns from "./table/columns";
import customStyles from "./table/customStyles";
import conditionalRowStyles from "./table/conditionalRowStyles";

const PatientsScreen = () => {
  const [isTutoMode, setIsTutoMode] = useState(false);
  const [patients, setPatients] = useState({ docs: [] });
  const [isLoading, setIsLoading] = useState(true);
  const [limit, setLimit] = useState(25);
  const [sort, setSort] = useState('{"lastName":1, "firstName":1}');
  const [currentPage, setCurrentPage] = useState(1);
  const [pumps, setPumps] = useState([]);
  const [currentPump, setCurrentPump] = useState();
  const [currentDiabeteType, setCurrentDiabeteType] = useState([]);
  const [infHbA1c, setInfHbA1c] = useState(null);
  const [supHbA1c, setSupHbA1c] = useState(null);
  const [currentTir, setCurrentTir] = useState("clear");
  const [dateInstalledBefore, setDateInstalledBefore] = useState(null);
  const [dateInstalledAfter, setDateInstalledAfter] = useState(null);
  const [lastDocRead, setLastDocRead] = useState("clear");
  const [hasRenewal, setHasRenewal] = useState(false);
  const [currentPrescriberFilter, setCurrentPrescriberFilter] = React.useState(
    []
  );
  const [filters, setFilters] = useState({
    patientFilter: [
      `limit=${limit}`,
      `page=${currentPage}`,
      `sort=${sort}`,
      `nor={"firstContract.startDate":{"$lt":{}}}`,
      `nor={"firstContract.startDate":{"$gte":{}}}`,
      `nor={"pump._id":""}`,
      `nor={"diabeteType.id":0}`,
      `nor={"lastIntervention.metrics.HbA1c":{"$lt":{}}}`,
      `nor={"lastIntervention.metrics.HbA1c":{"$gte":{}}}`,
      `nor={"lastIntervention.metrics.tir":""}`,
      `nor={"lastIntervention.lastDocRead":""}`,
      `nor={"lastIntervention.documents":""}`,
      `nor={"renewal.hasRenewal": "" }`,
    ],
    prescriberFilter: [],
  });
  const menuContext = useContext(MenuContext);
  const userReducer = useSelector(userSelector);
  const dispatch = useDispatch();
  const { afterToday } = DateRangePicker;

  useEffect(() => {
    async function defaultValue() {
      setPumps(await getPumps());

      const response = await getPatients(
        filters.patientFilter.concat(filters.prescriberFilter)
      );
      setPatients(response.data);
    }
    defaultValue();
  }, []);

  const getPatients = async (applyFilter = []) => {
    setIsLoading(true);
    const response = await patientController.getAll(
      applyFilter,
      userReducer.userToken
    );
    if (!checkStatus(response.status)) dispatch(logout());
    setIsLoading(false);
    return response;
  };

  const getPrescribers = async (applyFilter = []) => {
    const response = await prescriberController.getAll(
      applyFilter,
      userReducer.userToken
    );
    if (!checkStatus(response.status)) dispatch(logout());
    return response.data != null ? response.data.docs : [];
  };

  const getPumps = async () => {
    setIsLoading(true);
    const response = await deviceController.getPumps(userReducer.userToken);
    if (!checkStatus(response.status)) dispatch(logout());
    if (response.data != null) {
      let selectItems = [];
      response.data.map((item) =>
        selectItems.push({
          value: item._id,
          label: item.model ? item.model.replace("POMPE ", "") : null,
        })
      );
      return selectItems;
    }
    return [];
  };

  const resetSignedPrescriptions = async () => {
    setIsLoading(true);
    const response = await prescriptionController.reset(userReducer.userToken);
    if (!checkStatus(response.status)) dispatch(logout());
    if (response.data) {
      response.data.status === "OK"
        ? (window.location.href = "/")
        : setIsLoading(false);
    }
  };

  const handleFilterPatientChange = async (
    value = {
      newLimit: limit,
      newSort: sort,
      newPage: currentPage,
      dateInstalledBefore: null,
      dateInstalledAfter: null,
      pump: currentPump,
      diabeteType: currentDiabeteType,
      infHbA1c: null,
      supHbA1c: null,
      tir: currentTir,
      lastDocRead: lastDocRead,
      newHasRenewal: hasRenewal,
    }
  ) => {
    if (!isTutoMode) {
      let newFilters = filters;
      newFilters.patientFilter.map((filter, index) => {
        if (
          value.newLimit &&
          value.newLimit !== limit &&
          filter === `limit=${limit}`
        ) {
          newFilters.patientFilter[index] = filter = `limit=${value.newLimit}`;
          setLimit(value.newLimit);
        }
        if (value.newSort && filter === `sort=${sort}`) {
          newFilters.patientFilter[index] = filter = `sort=${value.newSort}`;
          setSort(value.newSort);
        }
        if (value.newPage && filter === `page=${currentPage}`) {
          newFilters.patientFilter[index] = filter = `page=${value.newPage}`;
          setCurrentPage(value.newPage);
        }

        if (!filter.includes("sort=")) {
          if (
            filter.includes("firstContract.startDate") &&
            filter.includes("firstContract.startDate")
          ) {
            if (value.dateInstalledBefore && filter.includes("$lt")) {
              if (value.dateInstalledBefore !== "clear") {
                setDateInstalledBefore(value.dateInstalledBefore);
                newFilters.patientFilter[index] =
                  filter = `and={"firstContract.startDate":{"$lt":{"$date":"${moment(
                    value.dateInstalledBefore
                  ).format("YYYY-MM-DD[T00:00:00.000Z]")}"}}}`;
              } else {
                setDateInstalledBefore(null);
                newFilters.patientFilter[index] =
                  filter = `nor={"firstContract.startDate":{"$lt":{}}}`;
              }
            }

            if (value.dateInstalledAfter && filter.includes("$gte")) {
              if (value.dateInstalledAfter !== "clear") {
                setDateInstalledAfter(value.dateInstalledAfter);
                newFilters.patientFilter[
                  index
                ] = `and={"firstContract.startDate":{"$gte":{"$date":"${moment(
                  value.dateInstalledAfter
                ).format("YYYY-MM-DD[T00:00:00.000Z]")}"}}}`;
              } else {
                setDateInstalledAfter(null);
                newFilters.patientFilter[
                  index
                ] = `nor={"firstContract.startDate":{"$gte":{}}}`;
              }
            }
          }

          if (value.pump && filter.includes("pump._id")) {
            if (value.pump !== "clear") {
              setCurrentPump(value.pump);
              newFilters.patientFilter[
                index
              ] = `and={"pump._id":${value.pump}}`;
            } else {
              setCurrentPump(null);
              newFilters.patientFilter[index] = `nor={"pump._id":""}`;
            }
          }

          if (value.diabeteType && filter.includes("diabeteType.id")) {
            if (value.diabeteType.length > 0) {
              newFilters.patientFilter[
                index
              ] = `and={"diabeteType.id":{"$in":[${value.diabeteType}]}}`;
              setCurrentDiabeteType(value.diabeteType);
            } else {
              newFilters.patientFilter[index] = `nor={"diabeteType.id":0}`;
              setCurrentDiabeteType([]);
            }
          }

          if (filter.includes("lastIntervention.metrics.HbA1c")) {
            if (value.infHbA1c && filter.includes("$lt")) {
              if (value.infHbA1c !== "clear") {
                setInfHbA1c(value.infHbA1c);
                newFilters.patientFilter[
                  index
                ] = `and={"lastIntervention.metrics.HbA1c":{"$lt":${value.infHbA1c}}}`;
              } else {
                setInfHbA1c(null);
                newFilters.patientFilter[
                  index
                ] = `nor={"lastIntervention.metrics.HbA1c":{"$lt":{}}}`;
              }
            }
            if (value.supHbA1c && filter.includes("$gte")) {
              if (value.supHbA1c !== "clear") {
                setSupHbA1c(value.supHbA1c);
                newFilters.patientFilter[
                  index
                ] = `and={"lastIntervention.metrics.HbA1c":{"$gte":${value.supHbA1c}}}`;
              } else {
                setSupHbA1c(null);
                newFilters.patientFilter[
                  index
                ] = `nor={"lastIntervention.metrics.HbA1c":{"$gte":{}}}`;
              }
            }
          }

          if (value.tir && filter.includes("lastIntervention.metrics.tir")) {
            if (value.tir !== "clear") {
              setCurrentTir(value.tir);
              newFilters.patientFilter[
                index
              ] = `and={"lastIntervention.metrics.tir":{"$lt":${value.tir}}}`;
            } else {
              setCurrentTir("clear");
              newFilters.patientFilter[
                index
              ] = `nor={"lastIntervention.metrics.tir":""}`;
            }
          }

          if (
            (userReducer.user.role === "prescriber" ||
              userReducer.user.role === "admin") &&
            userReducer.user.firstLogin &&
            value.lastDocRead &&
            filter.includes("lastIntervention.documents")
          ) {
            if (value.lastDocRead !== "clear" && userReducer.user.firstLogin) {
              setLastDocRead(value.lastDocRead);
              newFilters.patientFilter[
                index
              ] = `and={"lastIntervention.documents":{"$elemMatch":{"date":{"$gte":{"$date":"${moment(
                userReducer.user.firstLogin
              ).format(
                "YYYY-MM-DD[T00:00:00.000Z]"
              )}"}},"importType":"Courrier", "firstReader":{"$exists": false}}}}`;
            } else {
              setLastDocRead("clear");
              newFilters.patientFilter[
                index
              ] = `nor={"lastIntervention.documents":""}`;
            }
          }

          if (filter.includes("renewal.hasRenewal")) {
            if (value.newHasRenewal) {
              if (value.newHasRenewal !== "clear") {
                setHasRenewal(true);
                newFilters.patientFilter[
                  index
                ] = `and={"renewal.hasRenewal":${value.newHasRenewal}}`;
              } else {
                setHasRenewal(false);
                newFilters.patientFilter[
                  index
                ] = `nor={"renewal.hasRenewal":""}`;
              }
            }
          }
        }

        return filter;
      });
      setFilters(newFilters);
      const response = await getPatients(
        filters.patientFilter.concat(filters.prescriberFilter)
      );
      setPatients(response.data);
    }
  };

  return (
    <Section className="flex flex-col gap-3">
      <div>
        <span
          className="text-primary font-bold text-lg mr-2"
          style={{ fontFamily: "PoppinsBold" }}
        >
          Liste patients
        </span>
        <Toggle
          checked={isTutoMode}
          onChange={(checked) => setIsTutoMode(checked)}
          checkedChildren="Mode démo"
          unCheckedChildren="Mode démo"
        />
      </div>
      <div className="flex md:flex-row flex-col gap-3">
        <div className="flex flex-col gap-3">
          <div
            id="filter"
            className="bg-white rounded-lg shadow-xl md:w-fit h-fit lg:w-[220px]"
          >
            <div className="flex flex-row items-center gap-2 bg-primary text-white font-semibold rounded-t-lg py-2 px-3">
              <GoSettings size={12} className="rotate-90" />
              Filtrer
            </div>
            <div
              className={classNames(
                "flex flex-col p-3 gap-2",
                isTutoMode ? "pointer-events-none" : ""
              )}
            >
              {userReducer.user.role === "admin" ? (
                <TagPicker
                  label="Prescripteurs"
                  placeholder="Saisir un prescripteur"
                  value={currentPrescriberFilter}
                  onClose={async (removedItem) => {
                    let newFilters = filters;
                    newFilters.prescriberFilter =
                      newFilters.prescriberFilter.filter((item) => {
                        return !item.includes(
                          removedItem.split("(")[1].split(")")[0]
                        );
                      });
                    setCurrentPrescriberFilter(
                      currentPrescriberFilter.filter(
                        (item) => item !== removedItem
                      )
                    );
                    setFilters(newFilters);
                    const response = await getPatients(
                      filters.patientFilter.concat(filters.prescriberFilter)
                    );
                    setPatients(response.data);
                  }}
                  onSearch={async (word) => {
                    let items = [];
                    let filters = [];
                    const checkString = word && word !== " ";
                    if (checkString && /^[a-zA-Z ]+$/.test(word)) {
                      filters.push(
                        `or={"user.firstName":{"$regex":"${word}", "$options": "i"}}`,
                        `or={"user.lastName":{"$regex":"${word}", "$options": "i"}}`,
                        `or={"user.fullName":{"$regex":"${word}", "$options": "i"}}`
                      );
                    }
                    if (checkString && /^\d+$/.test(word))
                      filters.push(
                        `or={"_id":{"$regex":"${parseInt(word, 10)}"}}`
                      );
                    const response =
                      filters.length > 0 ? await getPrescribers(filters) : [];
                    response.map((item) =>
                      items.push({
                        label: `${item.user.fullName} (${item._id})`,
                        value: `${item.user.fullName} (${item._id})`,
                        role: item.user.role,
                        _id: `${item._id}`,
                      })
                    );
                    return { loading: false, items };
                  }}
                  onChanged={async (value = []) => {
                    let newFilters = filters;
                    let request = [];
                    if (Array.isArray(value))
                      value.map((item) => {
                        request.push(
                          `or={"prescriberId":${
                            item.split("(")[1].split(")")[0]
                          }}`
                        );
                        return item;
                      });
                    newFilters.prescriberFilter = request;
                    setCurrentPrescriberFilter(value);
                    setFilters(newFilters);
                    const response = await getPatients(
                      filters.patientFilter.concat(filters.prescriberFilter)
                    );
                    setPatients(response.data);
                  }}
                />
              ) : null}
              <div>
                <label className="text-xs text-neutral-dark  font-semibold">
                  Type de diabète
                </label>
                <CheckPicker
                  data={diabeteTypes}
                  value={currentDiabeteType}
                  placeholder="Choisir un type"
                  cleanable={false}
                  size="sm"
                  block
                  searchable={false}
                  onChange={async (value) =>
                    await handleFilterPatientChange({
                      diabeteType: value,
                    })
                  }
                />
              </div>

              <div>
                <label className="text-xs text-neutral-dark font-semibold mb-1">
                  Date prestation:
                </label>
                <div className="mb-2">
                  <span className="text-xs text-primary">
                    Installé depuis le :
                  </span>
                  <DatePicker
                    locale={Locale}
                    format="DD/MM/YYYY"
                    placeholder="Choisir une date"
                    size="sm"
                    disabledDate={afterToday()}
                    block
                    value={dateInstalledAfter}
                    onChange={async (value) =>
                      await handleFilterPatientChange({
                        dateInstalledAfter: value ? value : "clear",
                      })
                    }
                  />
                </div>
                <div>
                  <span className="text-xs text-primary">
                    Installé avant le :
                  </span>
                  <DatePicker
                    locale={Locale}
                    format="DD/MM/YYYY"
                    placeholder="Choisir une date"
                    size="sm"
                    disabledDate={afterToday()}
                    block
                    value={dateInstalledBefore}
                    onChange={async (value) =>
                      await handleFilterPatientChange({
                        dateInstalledBefore: value ? value : "clear",
                      })
                    }
                  />
                </div>
              </div>

              <hr className="my-2" />

              <div className="flex flex-col gap-1">
                <span className="text-xs text-neutral-dark font-semibold">
                  Type de pompe
                </span>
                <SelectPicker
                  data={pumps}
                  value={currentPump}
                  placeholder="Choisir une pompe"
                  size="sm"
                  placement="bottomStart"
                  block
                  searchable={false}
                  onChange={async (value) =>
                    await handleFilterPatientChange({
                      pump: value ? value : "clear",
                    })
                  }
                />
              </div>

              <div className="flex flex-col gap-1">
                <span className="text-xs text-neutral-dark font-semibold">
                  HbA1c
                </span>
                <InputNumber
                  size="sm"
                  prefix="<"
                  postfix="%"
                  min={0}
                  onChange={async (value) => {
                    if (checkNumber(value))
                      await handleFilterPatientChange({ infHbA1c: value });
                    if (value === "")
                      await handleFilterPatientChange({
                        infHbA1c: "clear",
                      });
                  }}
                />
                <InputNumber
                  size="sm"
                  prefix=">"
                  className="mt-1"
                  postfix="%"
                  min={0}
                  step={1}
                  onChange={async (value) => {
                    if (checkNumber(value))
                      await handleFilterPatientChange({ supHbA1c: value });
                    if (value === "")
                      await handleFilterPatientChange({
                        supHbA1c: "clear",
                      });
                  }}
                />
              </div>

              <div>
                <label className="text-xs text-neutral-dark font-semibold">
                  TIR
                </label>
                <SelectPicker
                  data={tirList}
                  defaultValue={currentTir}
                  value={currentTir}
                  cleanable={false}
                  size="sm"
                  block
                  searchable={false}
                  onChange={async (value) =>
                    await handleFilterPatientChange({
                      tir: value ? value : "clear",
                    })
                  }
                />
              </div>

              {(userReducer.user?.role === "prescriber" ||
                userReducer.user?.role === "admin") &&
              userReducer.user?.firstLogin ? (
                <div>
                  <Checkbox
                    checked={lastDocRead === "true"}
                    value={lastDocRead === "true"}
                    onChange={async (value, checked) => {
                      await handleFilterPatientChange({
                        lastDocRead: checked ? "true" : "clear",
                      });
                    }}
                  >
                    <div className="font-bold text-xs text-primary">
                      CR non lu
                    </div>
                    <div className="text-xs tewt-secondary">
                      {`Première connexion le ${moment(
                        userReducer.user?.firstLogin
                      ).format("DD/MM/YYYY à HH:mm")}`}
                    </div>
                  </Checkbox>
                  {/*<TutoSpeechBubble
                        text="Par exemple : Cette case permet de filtrer les patients pour lesquels un CR n’a pas encore été consulté."
                        position={{ top: "-140%", left: "45%" }}
                        isVisible={isTutoMode}
                        pointPlacement="bottom-left"
                      />*/}
                </div>
              ) : null}
              {userReducer.user?.role === "prescriber" ||
              userReducer.user?.role === "admin" ||
              process.env.REACT_APP_DEMO ? (
                <div>
                  <Checkbox
                    checked={hasRenewal}
                    onChange={async (value, checked) => {
                      await handleFilterPatientChange({
                        newHasRenewal: checked ? "true" : "clear",
                      });
                    }}
                  >
                    <div className="font-bold text-xs text-primary">
                      Prescription à renouveler
                    </div>
                    <TutoSpeechBubble
                      text="Cette option permet de filtrer les patients en attente de renouvellement."
                      isVisible={isTutoMode}
                      pointType="default"
                      className="lg:block top-[130%] left-[25px] sm:top-[100%] sm:left-[15%]"
                      pointPlacement="top-left"
                    />
                  </Checkbox>
                </div>
              ) : null}
              <ButtonIcon
                className="bg-primary text-white p-1 w-full"
                text="Réinitialiser"
                textClassName=""
                style={{ borderRadius: 5 }}
                onClick={async () => {
                  await handleFilterPatientChange({
                    dateInstalledBefore: "clear",
                    dateInstalledAfter: "clear",
                    pump: "clear",
                    diabeteType: 1,
                    infHbA1c: "clear",
                    supHbA1c: "clear",
                    tir: "clear",
                    lastDocRead: "clear",
                    newHasRenewal: "clear",
                  });
                }}
              />
            </div>
          </div>
          {process.env.REACT_APP_DEMO ? (
            <ButtonIcon
              className="bg-dark hover:bg-secondary text-white p-1 w-full"
              text="Réinitialiser les signatures"
              textClassName=""
              style={{ borderRadius: 5 }}
              onClick={async () => await resetSignedPrescriptions()}
            />
          ) : null}
        </div>

        <div id="dataTable" className="w-full h-full rounded lg:mr-2">
          <DataTable
            columns={columns({
              isTutoMode,
              access_token: userReducer.userToken,
            })}
            className={classNames(
              "rounded-b-xl",
              isTutoMode ? "!pointer-events-none" : ""
            )}
            data={patients.docs || []}
            noDataComponent={
              <span className="w-full text-center font-semibold">
                Pas de données
              </span>
            }
            customStyles={customStyles(isTutoMode, menuContext?.isOpen)}
            conditionalRowStyles={conditionalRowStyles}
            defaultSortFieldId={1}
            defaultSortAsc={false}
            sortIcon={<GoChevronDown className="ml-1" color="var(--light)" />}
            striped
            highlightOnHover
            pagination
            theme="solarized"
            paginationPerPage={limit}
            paginationRowsPerPageOptions={[25, 50, 100]}
            progressPending={isLoading}
            progressComponent={<Loader className="w-full text-center" />}
            paginationServer
            paginationDefaultPage={patients.page}
            paginationServerOptions={{
              persistSelectedOnPageChange: true,
              persistSelectedOnSort: true,
            }}
            paginationTotalRows={patients.totalDocs}
            onChangePage={async (page) =>
              await handleFilterPatientChange({ newPage: page })
            }
            onChangeRowsPerPage={async (newPerPage) =>
              await handleFilterPatientChange({
                newLimit: newPerPage,
                newPage: 1,
              })
            }
            sortServer
            onSort={async (column, sortDirection) =>
              await handleFilterPatientChange({
                newSort: `{"${column.sortfield}":${
                  sortDirection === "desc" ? -1 : 1
                }${
                  column.sortfield === "lastName"
                    ? `,"firstName":${sortDirection === "desc" ? -1 : 1}`
                    : ""
                }${
                  column.sortfield === "prescriber"
                    ? `,"precriber":${sortDirection === "desc" ? -1 : 1}`
                    : ""
                }}`,
                newPage: 1,
              })
            }
            dense
            paginationComponentOptions={{
              rowsPerPageText: "Patient par page:",
              rangeSeparatorText: "sur",
              noRowsPerPage: false,
              selectAllRowsItem: false,
              selectAllRowsItemText: "All",
            }}
          />
        </div>
      </div>
      <TutoSpeechBubble
        text="Un champ de « Recherche » permet de cibler précisément un patient."
        position={{ top: 70, right: "14%" }}
        isVisible={isTutoMode}
        pointType="default"
        className="hidden lg:block"
        pointPlacement="top-right"
      />
    </Section>
  );
};

export default PatientsScreen;
