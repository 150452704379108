import { createSlice } from "@reduxjs/toolkit";

export const appSlice = createSlice({
  name: "app",
  initialState: {
    patientId: null,
  },
  reducers: {},
});

//export const {} = appSlice.actions;

export const appSelector = (state) => state.appReducer;

export default appSlice.reducer;
