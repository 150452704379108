import {
  Loader,
  DatePicker,
  Radio,
  RadioGroup,
  InputNumber,
  Input,
  Checkbox,
  SelectPicker,
} from "rsuite";
import { AiOutlineClose } from "react-icons/ai";
import classNames from "classnames";
import moment from "moment";

import ModalComponent from "components/Modal";

import { prescriptionController } from "api/controllers";

import { Locale, checkSignable } from "../constants";

const ModalRenewal = (props) => {
  const {
    id,
    prescription,
    prescriber,
    patient,
    accessToken,
    OpenComponent,
    isModalLoading,
    setIsModalLoading,
    settings,
    careSetChecked,
    setCareSetChecked,
    tegaderm6X7Checked,
    setTegaderm6X7Checked,
    tegaderm9X10Checked,
    setTegaderm9X10Checked,
    otherFiness,
    setOtherFiness,
    finess,
    finessSelectValue,
    setFinessSelectValue,
    finessOther,
    setFinessOther,
    socialReason,
    setSocialReason,
    catheter,
    setCatheter,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    careSet,
    setCareSet,
    tegaderm6X7,
    setTegaderm6X7,
    tegaderm9X10,
    setTegaderm9X10,
    autocontrol,
    setAutocontrol,
    memberId,
    setMemberId,
    missingItems,
    setMissingItems,
  } = props;

  const handleRefresh = async () => {
    if (memberId) {
      setIsModalLoading(true);
      await new Promise((resolve) => setTimeout(resolve, 2000));
      window.location.reload();
    } else {
      return true;
    }
  };

  const signPrescription = async () => {
    setIsModalLoading(true);
    if (
      checkSignable({
        code: prescription.contract.plan.code,
        finess:
          finessSelectValue.value !== "other" ? finessSelectValue.label : "",
        finessOther,
        socialReason,
        startDate,
        endDate,
        catheter,
      })
    ) {
      setMissingItems(false);
      const response = await prescriptionController.sign(
        prescription._id,
        {
          patient,
          prescriber,
          finess:
            finessSelectValue.value !== "other"
              ? finessSelectValue.label
              : finessOther + " - " + socialReason,
          startDate: moment(startDate).format("DD/MM/YYYY"),
          endDate: moment(endDate).format("DD/MM/YYYY"),
          careSet,
          tegaderm6X7,
          tegaderm9X10,
          autocontrol,
          catheter,
        },
        accessToken
      );
      if (response.data != null) setMemberId(response.data.memberId);
    } else {
      setMissingItems(true);
    }

    setIsModalLoading(false);
  };

  const Content = ({ onCloseModal }) => (
    <div className="rounded-2xl p-5">
      <div className="flex flex-row justify-between items-center pb-2">
        <span className="font-semibold text-base font-[PoppinsSemiBold]">
          RENOUVELLEMENT {settings.label}
        </span>
        <AiOutlineClose
          className="delete text-primary hover:text-light cursor-pointer"
          aria-label="close"
          size={23}
          onClick={onCloseModal}
        />
      </div>
      <div
        className={classNames(
          "flex flex-col gap-5",
          memberId ? "w-[80vw] h-[70vh]" : "w-fit h-fit"
        )}
      >
        {isModalLoading ? (
          <div className="w-full h-full flex justify-center items-center">
            <Loader />
          </div>
        ) : memberId ? (
          <div
            className="w-full h-full"
            dangerouslySetInnerHTML={{
              __html:
                "<iframe " +
                'className="w-full h-full" ' +
                'src="' +
                process.env.REACT_APP_YOUSIGN_IFRAME_URL +
                "procedure/sign?members=" +
                memberId +
                "&signatureUi=/signature_uis/" +
                process.env.REACT_APP_YOUSIGN_UUID +
                '" style="width:100%;height:100%;" />',
            }}
          />
        ) : (
          <div className="flex flex-col">
            <span>
              Vous vous apprêtez à renouveler la prescription de{" "}
              <span className="font-bold">{settings.label}</span> pour le
              patient <span className="font-bold">{prescription.patient}</span>{" "}
              <span>pour la période suivante :</span>
            </span>
            <div className="flex gap-5 py-3 border-b border-b-gray-300">
              <div>
                <label className="text-xs text-neutral-dark font-semibold mb-1">
                  Date de début :
                </label>
                <DatePicker
                  locale={Locale}
                  format="DD/MM/YYYY"
                  placeholder="Choisir une date"
                  className="z-30"
                  size="sm"
                  block
                  defaultValue={startDate}
                  onChange={(value) => {
                    setStartDate(value);
                    setEndDate(
                      moment(value)
                        .subtract(1, "days")
                        .add(6, "months")
                        .toDate()
                    );
                  }}
                />
              </div>

              <div>
                <label className="text-xs text-neutral-dark font-semibold mb-1">
                  Date de fin :
                </label>
                <DatePicker
                  locale={Locale}
                  format="DD/MM/YYYY"
                  className="z-30"
                  placeholder="Choisir une date"
                  size="sm"
                  block
                  value={endDate}
                  onChange={setEndDate}
                />
              </div>
            </div>
            <div className="flex flex-col gap-2 border-b border-b-gray-300 py-3">
              <p className="">
                Veuillez selectionner le{" "}
                <span className="font-bold">finess</span>
                <span className="text-red-500">*</span>
              </p>
              <SelectPicker
                name="finess"
                searchable={false}
                className="z-30"
                data={[
                  ...finess.map((item, index) => ({
                    label: item.finnesNumber + " - " + item.name,
                    value: String(index),
                  })),
                  { label: "Autre", value: "other" },
                ]}
                placeholder="Finess"
                block
                size="sm"
                value={finessSelectValue.value}
                onChange={(value) => {
                  if (value !== "other") {
                    setFinessSelectValue({
                      label:
                        finess[Number(value)].finnesNumber +
                        " - " +
                        finess[Number(value)].name,
                      value,
                    });
                    setOtherFiness(false);
                  } else {
                    setFinessSelectValue({ label: "", value: "other" });
                    setOtherFiness(true);
                  }
                }}
                onClean={() => {
                  setFinessSelectValue({ label: "", value: "other" });
                  setOtherFiness(true);
                }}
              />
              {otherFiness ? (
                <>
                  <div className="flex flex-col sm:flex-row justify-between gap-2">
                    <div className="w-full">
                      <p className="">
                        Code finess ou numéro Adeli
                        <span className="text-red-500">*</span>
                      </p>
                      <Input
                        name="finess-other"
                        value={finessOther}
                        onChange={setFinessOther}
                        size="sm"
                      />
                    </div>

                    <div className="w-full">
                      <p className="">
                        Raison sociale de l'établissement
                        <span className="text-red-500">*</span>
                      </p>
                      <Input
                        name="social-reason"
                        value={socialReason}
                        onChange={setSocialReason}
                        size="sm"
                      />
                    </div>
                  </div>
                  <p className="text-red-500 text-[9px] sm:text-xs">
                    Merci de renseigner votre code finess ou votre numéro Adeli
                    ainsi que la raison sociale de l'établissement.
                  </p>
                </>
              ) : null}
            </div>

            {settings.careSet ? (
              <div className="flex items-center py-3 border-b border-b-gray-300">
                <div
                  className={classNames(
                    careSetChecked ? "w-1/2 border-r-2 pr-4" : ""
                  )}
                >
                  <p className="">
                    Souhaitez-vous prescrire des{" "}
                    <span className="font-bold">sets de soins</span> ?{" "}
                    <span className="text-red-500">*</span>
                  </p>
                  <RadioGroup
                    name="radioList"
                    inline
                    defaultValue="true"
                    value={String(careSetChecked)}
                    onChange={(value) => {
                      if (value === "true") {
                        setCareSetChecked(true);
                        setCareSet(1);
                      } else if (value === "false") {
                        setCareSetChecked(false);
                        setCareSet(0);
                      }
                    }}
                    className="flex gap-5"
                  >
                    <Radio value="true" checked>
                      Oui
                    </Radio>
                    <Radio value="false">Non</Radio>
                  </RadioGroup>
                </div>
                <div className={classNames(careSetChecked ? "w-1/2 pl-4" : "")}>
                  {careSetChecked ? (
                    <div className="flex flex-col gap-2">
                      <p className="font-semibold">
                        Quantité de boîtes par mois{" "}
                        <span className="text-red-500">*</span>
                      </p>
                      <div className="flex items-center gap-2">
                        <div style={{ width: 80 }}>
                          <InputNumber
                            value={careSet}
                            step={1}
                            min={1}
                            max={3}
                            onChange={setCareSet}
                            size="sm"
                          />
                        </div>
                        <p>Boîte(s)</p>
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>
            ) : null}

            {settings.tegaderm6X7 ? (
              <div className="flex items-center py-3 border-b border-b-gray-300">
                <div
                  className={classNames(
                    tegaderm6X7Checked ? "w-1/2 border-r-2 pr-4" : ""
                  )}
                >
                  <p className="">
                    Souhaitez-vous prescrire des{" "}
                    <span className="font-bold">Tegaderm 6x7cm</span> ?{" "}
                    <span className="text-red-500">*</span>
                  </p>
                  <RadioGroup
                    name="radioList"
                    inline
                    defaultValue="true"
                    value={String(tegaderm6X7Checked)}
                    onChange={(value) => {
                      if (value === "true") {
                        setTegaderm6X7Checked(true);
                        setTegaderm6X7(1);
                      } else if (value === "false") {
                        setTegaderm6X7Checked(false);
                        setTegaderm6X7(0);
                      }
                    }}
                    className="flex gap-5"
                  >
                    <Radio value="true" checked>
                      Oui
                    </Radio>
                    <Radio value="false">Non</Radio>
                  </RadioGroup>
                </div>
                <div
                  className={classNames(tegaderm6X7Checked ? "w-1/2 pl-4" : "")}
                >
                  {tegaderm6X7Checked ? (
                    <div className="flex flex-col gap-2">
                      <p className="font-semibold">
                        Quantité de boîtes par mois{" "}
                        <span className="text-red-500">*</span>
                      </p>
                      <div className="flex items-center gap-2">
                        <div style={{ width: 80 }}>
                          <InputNumber
                            value={tegaderm6X7}
                            step={1}
                            min={1}
                            max={3}
                            onChange={setTegaderm6X7}
                            size="sm"
                          />
                        </div>
                        <p>Boîte(s)</p>
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>
            ) : null}

            {settings.tegaderm9X10 ? (
              <div
                className={classNames(
                  "flex items-center py-3 border-b border-b-gray-300",
                  settings.autocontrol || settings.catheter
                    ? "border-b border-b-gray-300"
                    : null
                )}
              >
                <div
                  className={classNames(
                    tegaderm9X10Checked ? "w-1/2 border-r-2 pr-4" : ""
                  )}
                >
                  <p className="">
                    Souhaitez-vous prescrire des{" "}
                    <span className="font-bold">Tegaderm 9x10cm</span> ?{" "}
                    <span className="text-red-500">*</span>
                  </p>
                  <RadioGroup
                    name="tegaderm9X10"
                    inline
                    defaultValue="true"
                    value={String(tegaderm9X10Checked)}
                    onChange={(value) => {
                      if (value === "true") {
                        setTegaderm9X10Checked(true);
                        setTegaderm9X10(1);
                      } else if (value === "false") {
                        setTegaderm9X10Checked(false);
                        setTegaderm9X10(0);
                      }
                    }}
                    className="flex gap-5"
                  >
                    <Radio value="true" checked>
                      Oui
                    </Radio>
                    <Radio value="false">Non</Radio>
                  </RadioGroup>
                </div>
                <div
                  className={classNames(
                    tegaderm9X10Checked ? "w-1/2 pl-4" : ""
                  )}
                >
                  {tegaderm9X10Checked ? (
                    <div className="flex flex-col gap-2">
                      <p className="font-semibold">
                        Quantité de boîtes par mois{" "}
                        <span className="text-red-500">*</span>
                      </p>
                      <div className="flex items-center gap-2">
                        <div style={{ width: 80 }}>
                          <InputNumber
                            value={tegaderm9X10}
                            step={1}
                            min={1}
                            max={3}
                            onChange={setTegaderm9X10}
                            size="sm"
                          />
                        </div>
                        <p>Boîte(s)</p>
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>
            ) : null}
            {settings.catheter ? (
              <div className="flex flex-col gap-2 py-3">
                <p className="">
                  Saisissez le{" "}
                  <span className="font-bold">modèle de cathéter</span>{" "}
                  <span className="text-red-500">*</span>
                </p>
                <Input
                  name="catheter"
                  value={catheter}
                  onChange={setCatheter}
                  size="sm"
                />
              </div>
            ) : null}
            {settings.autocontrol ? (
              <div className="flex py-3 items-center">
                <Checkbox
                  name="autocontrol"
                  onChange={(value, checked) => setAutocontrol(checked)}
                />
                <p className="">
                  Autocontrôle du glucose interstitiel, forfait mensuel
                  capteurs, MEDTRONIC, ENLITE (LPP 1170862)
                  <span className="text-red-500">*</span>
                </p>
              </div>
            ) : null}
          </div>
        )}
      </div>
      {missingItems ? (
        <p className="text-red-500 text-[9px] sm:text-xs">
          Veuillez remplir tous les champs obligatoires.
        </p>
      ) : null}
      <div className=" flex justify-end mt-2">
        {!memberId ? (
          <button
            type="button"
            className={classNames(
              "border border-dark px-3 py-1 w-fit font-semibold text-dark rounded hover:bg-dark hover:text-white",
              isModalLoading ? "invisible" : ""
            )}
            onClick={async () => signPrescription()}
            disabled={isModalLoading}
          >
            Signer le renouvellement
          </button>
        ) : (
          <button
            type="button"
            className="border border-primary px-3 py-1 mt-2 w-fit font-semibold text-primary rounded hover:bg-primary hover:text-white"
            onClick={onCloseModal}
          >
            Fermer
          </button>
        )}
      </div>
    </div>
  );

  return (
    <ModalComponent
      id={id}
      Content={Content}
      OpenComponent={OpenComponent}
      closeHandle={async () => await handleRefresh()}
    />
  );
};

export default ModalRenewal;
