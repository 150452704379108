import ElementRenewal from "./elementRenewal";

const CardRenewal = (props) => {
  const { prescriptions = [] } = props;
  return (
    <div className="shadow-xl rounded-xl h-fit">
      <div className="bg-primary text-white p-2 px-3 rounded-t-xl font-semibold">
        <p>PRESCRIPTIONS À RENOUVELER</p>
      </div>
      <div className="bg-white p-3 rounded-b-xl min-h-[50px]">
        {prescriptions && Array.isArray(prescriptions) ? (
          prescriptions.length > 0 ? (
            prescriptions.map((prescription, index) => (
              <span key={prescription._id}>
                <ElementRenewal
                  key={prescription._id}
                  prescription={prescription}
                  {...props}
                />
                {index !== prescriptions.length - 1 ? (
                  <hr
                    className="has-background-dark mt-1 mb-4"
                    style={{ height: 1 }}
                  />
                ) : null}
              </span>
            ))
          ) : (
            <p className="font-semibold text-center px-10 py-2">
              Aucune prescription à renouveler pour ce patient.
            </p>
          )
        ) : (
          <p className="font-semibold text-center px-10 py-2">
            Aucune prescription à renouveler pour ce patient.
          </p>
        )}
      </div>
    </div>
  );
};

export default CardRenewal;
