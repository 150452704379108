import React, { useEffect } from "react";
import { Router, Switch, Route, Redirect } from "react-router-dom";
import { createTheme } from "react-data-table-component";

import { history } from "./services";
import { useSelector } from "react-redux";
import { userSelector } from "./redux/user/slice";

import "./styles/Auth.css";
import "./theme/rsuite-default.css";
import "./styles/Rsuite.custom.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import {
  Error404Screen,
  LoginScreen,
  ForgotPasswordScreen,
  ResetPasswordScreen,
  VerifyScreen,
  PatientsScreen,
  PatientScreen,
  PrescribersScreen,
  FileScreen,
  AccountScreen,
  MonitoringScreen,
  LogScreen,
  StatisticScreen,
  CookiePolicyScreen,
} from "./views";

createTheme("solarized", {
  text: {
    primary: "#808485",
    secondary: "#808485",
  },
  background: {
    default: "white",
  },
  divider: {
    default: "transparent",
  },
  action: {
    button: "white",
    hover: "white",
    disabled: "white",
  },
  highlightOnHover: {
    default: "#F2F3F5",
    text: "##808485",
  },
  striped: {
    default: "#F6F9FF",
    text: "#808485",
  },
});

const App = () => {
  const userReducer = useSelector(userSelector);

  useEffect(() => {
    async function checkAccess() {
      //if (userReducer.userToken) dispatch(checkToken(userReducer.userToken));
    }
    checkAccess();
  }, []);

  const AdminRouter = () => (
    <Router history={history}>
      <Switch>
        <Route exact path="/">
          <Redirect to="/patients" />
        </Route>
        <Route exact path="/patients">
          <PatientsScreen />
        </Route>
        {!process.env.REACT_APP_DEMO ? (
          <Route exact path="/prescripteurs">
            <PrescribersScreen />
          </Route>
        ) : null}
        <Route path="/patients/:id">
          <PatientScreen />
        </Route>
        <Route path="/file/:type/:id">
          <FileScreen />
        </Route>
        <Route exact path="/account">
          <AccountScreen />
        </Route>
        <Route exact path="/monitoring">
          <MonitoringScreen />
        </Route>
        <Route exact path="/logs">
          <LogScreen />
        </Route>
        <Route exact path="/statistics">
          <StatisticScreen />
        </Route>
        <Route>
          <Error404Screen />
        </Route>
      </Switch>
    </Router>
  );

  const DoctorRouter = () => (
    <Router history={history}>
      <Switch>
        <Route exact path="/">
          <Redirect to="/patients" />
        </Route>
        <Route exact path="/patients">
          <PatientsScreen />
        </Route>
        <Route path="/patients/:id">
          <PatientScreen />
        </Route>
        <Route path="/file/:type/:id">
          <FileScreen />
        </Route>
        <Route exact path="/account">
          <AccountScreen />
        </Route>

        <Route>
          <Error404Screen />
        </Route>
      </Switch>
    </Router>
  );

  const PatientRouter = () => (
    <Router history={history}>
      <Switch>
        <Route exact path="/">
          <PatientScreen />
        </Route>
        <Route path="/file/:type/:id">
          <FileScreen />
        </Route>
        <Route exact path="/account">
          <AccountScreen />
        </Route>

        <Redirect to="/" />
      </Switch>
    </Router>
  );

  if (userReducer.isLoggedIn) {
    switch (userReducer.user.role) {
      case "admin":
        return <AdminRouter />;
      case "prescriber":
        return <DoctorRouter />;
      case "patient":
        return <PatientRouter />;
      default:
        return <Error404Screen />;
    }
  } else {
    return (
      <Router history={history}>
        <Switch>
          <Route exact path="/politique-cookies">
            <CookiePolicyScreen />
          </Route>
          <Route exact path="/forgot">
            <ForgotPasswordScreen />
          </Route>
          <Route path="/reset/:id">
            <ResetPasswordScreen />
          </Route>
          <Route path="/verify/:id">
            <VerifyScreen />
          </Route>
          <Route exact path="/">
            <LoginScreen />
          </Route>
          <Route>
            <Redirect to="/" />
          </Route>
        </Switch>
      </Router>
    );
  }
};

export default App;
