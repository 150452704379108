import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { BsChevronLeft } from "react-icons/bs";
import { Loader } from "rsuite";
import validator from "validator";
import classNames from "classnames";

import { authController } from "../../../api/controllers";
import { history } from "../../../services";
import Input from "components/Input";
import AuthSection from "components/AuthSection";

const VerifyScreen = () => {
  const [password, setPassword] = useState(null);
  const [errPassword, setErrPassword] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const { handleSubmit } = useForm();
  const id = useParams().id;
  const errPasswordMessage =
    "Le mot de passe doit contenir au moins 8 caractères dont 1 majuscule, 1 minuscule, 1 nombre, 1 symbole.";

  useEffect(() => {
    async function defaultValue() {}
    defaultValue();
  }, []);

  const isValidePassword = (val) => {
    if (
      validator.isStrongPassword(val, {
        minLength: 8,
        minLowercase: 1,
        minUppercase: 1,
        minNumbers: 1,
        minSymbols: 1,
      })
    ) {
      setPassword(val);
      setErrPassword(null);
      return true;
    } else {
      setPassword(null);
      setErrPassword(errPasswordMessage);
      return false;
    }
  };

  const handleVerify = async () => {
    if (password) {
      setIsLoading(true);
      const response = await authController.verify({
        id,
        password,
      });
      setIsLoading(false);
      if (response.data?.verified) {
        history.push("/?notif=verify_success");
      }
      setPassword(null);
    } else {
      setErrPassword(errPasswordMessage);
    }
  };

  if (isLoading) return <Loader />;

  return (
    <AuthSection className="flex flex-col gap-3">
      <form
        className="flex flex-col gap-2"
        onSubmit={handleSubmit(handleVerify)}
        method="POST"
      >
        <span>
          Veuillez saisir dans le champ ci-dessous un nouveau mot de passe pour
          finaliser votre compte. Vous serez redirigé à la page de connexion.
        </span>
        <div>
          <Input
            label="Nouveau mot de passe"
            type="password"
            required
            className={errPassword ? "!border-red-500" : null}
            placeholder={"Veuillez saisir un nouveau mot de passe"}
            onChange={isValidePassword}
          />
          <div
            className={classNames(
              "text-red-500 text-xs",
              errPassword ? "d-block" : "hidden"
            )}
          >
            {errPassword}
          </div>
        </div>
        <button
          className="rounded border border-primary py-1 px-10 p w-fit mx-auto text-primary hover:bg-primary hover:text-white my-2"
          disabled={password === null}
        >
          Enregister
        </button>
        <a
          href="/"
          className="text-sm text-blue-800 underline w-fit flex flex-row items-center space-x-1"
        >
          <BsChevronLeft />
          <span className="underline">Retour</span>
        </a>
      </form>
    </AuthSection>
  );
};

export default VerifyScreen;
