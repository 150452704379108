export const removeItemAll = (arr, value) => {
  var i = 0;
  while (i < arr.length) {
    if (arr[i] === value) {
      arr.splice(i, 1);
    } else {
      ++i;
    }
  }
  return arr;
};
export const checkNumber = (value) => {
  let x = null;
  if (!value.includes(".")) x = Number(value);
  if (
    value.includes(".") &&
    value.split(".")[1] &&
    value.split(".")[1] !== ""
  ) {
    x = Number(value);
  }
  return typeof x === "number" && !isNaN(x);
};

export const checkTypeDocDemo = (typeDoc) => {
  switch (typeDoc) {
    case "Courrier":
      return "Modèle Courrier FTC.pdf";
    case "Évaluation":
      return "Modèle Évaluation FTC.pdf";
    case "Téléchargement Dispositif":
      return "Modèle téléchargement dispositif.pdf";
    default:
      return "Modèle Courrier FTC.pdf";
  }
};
