import React from "react";

const Error404Screen = () => {
  return (
    <section className="text-neutral-dark text-lg flex justify-center items-center h-screen w-screen">
      Page non trouvée.
    </section>
  );
};

export default Error404Screen;
