import React, { useEffect, useState, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AutoComplete, InputGroup, Dropdown } from "rsuite";
import { Link, useLocation } from "react-router-dom";
import classNames from "classnames";
import { GiHamburgerMenu } from "react-icons/gi";
import { AiOutlineClose } from "react-icons/ai";
import { FaRegUser, FaUserMd, FaUserAlt } from "react-icons/fa";
import SearchIcon from "@rsuite/icons/Search";

import { MenuContext } from "../../contexts/MenuContext";

import "./Navbar.css";
import { userController } from "../../api/controllers";
import { logout, userSelector } from "../../redux/user/slice";
import { checkStatus } from "../../services";

const NavBar = () => {
  const [openNav, setOpenNav] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const [searchItems, setSearchItems] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [routes, setRoutes] = useState([]);

  const menuContext = useContext(MenuContext);
  const dispatch = useDispatch();
  const userReducer = useSelector(userSelector);
  const location = useLocation();
  const isActive = (pathname) => location.pathname === pathname;
  const routesPatients = [];
  const routesPrescripteurs = [
    {
      name: "PATIENTS",
      path: "patients",
    },
  ];
  let routesAdmin = [
    {
      name: "PATIENTS",
      path: "patients",
    },
  ];

  useEffect(() => {
    switch (userReducer.user.role) {
      case "patient":
        setRoutes(routesPatients);
        break;
      case "prescriber":
        setRoutes(routesPrescripteurs);
        break;
      case "admin":
        {
          if (!process.env.REACT_APP_DEMO) {
            routesAdmin.push({
              name: "ADMINISTRATION",
              dropdown: true,
              admin: true,
              items: [
                { name: "PRESCRIPTEURS", path: "prescripteurs" },
                { name: "MONITORING", path: "monitoring" },
                { name: "LOGS", path: "logs" },
                { name: "STATISTIQUES", path: "statistics" },
              ],
            });
          }
          setRoutes(routesAdmin);
        }
        break;
      case "superAdmin":
        setRoutes(routesAdmin);
        break;
      default:
        setRoutes([]);
    }
    const controller = new AbortController();
    window.addEventListener(
      "resize",
      () => window.innerWidth >= 960 && setOpenNav(false),
      { signal: controller.signal }
    );
    return () => controller.abort();
  }, []);

  const logoutHandle = () => {
    dispatch(logout());
  };

  const getPatientItems = async (word = "") => {
    let items = [];
    setSearchValue(word);
    const checkString = word && word !== "" && word !== " ";
    let applyFilter = [];
    if (checkString && /^[a-zA-Z ]+$/.test(word)) {
      applyFilter = [
        `limit=10`,
        `and={"role":"patient"} `,
        `or={"firstName":{"$regex":"${word}", "$options": "i"}}`,
        `or={"lastName":{"$regex":"${word}", "$options": "i"}}`,
        `or={"fullName":{"$regex":"${word}", "$options": "i"}}`,
        `or={"email":{"$regex":"${word}", "$options": "i"}}`,
      ];
    } else {
      if (checkString && !isNaN(word))
        applyFilter.push(`or={"roleId":${word}}`, `or={"rpps":${word}}`);
    }
    const response = await userController.getAll(
      applyFilter,
      userReducer.userToken
    );
    if (!checkStatus(response.status)) dispatch(logout());
    const docs = response.data != null ? response.data.docs : [];
    docs.map((doc) =>
      items.push({
        label: `${doc.fullName}`,
        value: `${doc.roleId}`,
        groupBy: doc.role,
      })
    );

    setSearchItems(items);
  };

  const NavList = () => (
    <ul
      className={classNames([
        !openNav ? "hidden lg:flex" : "",
        "flex flex-col gap-2 lg:mb-0 lg:mt-0 lg:flex-row lg:items-center lg:gap-20 lg:ml-10",
      ])}
    >
      {routes.map((route, index) => {
        if (route.dropdown) {
          return (
            <li key={index}>
              <Dropdown
                title={
                  <span
                    className="font-semibold text-primary"
                    style={{ fontFamily: "PoppinsSemiBold" }}
                  >
                    {route.name}
                  </span>
                }
                placement="bottomStart"
                className="p-0 bg-transparent hidden lg:!flex !flex-col gap-"
                toggleClassName="text-white lg:text-primary hover:text-secondary hover:bg-transparent lg:hover:scale-105 !hidden lg:!block !px-0"
              >
                {route.items.map((item, index) => (
                  <Dropdown.Item
                    key={index}
                    className={classNames(
                      "relative top-[0.2rem] text-white hover:text-white text-center",
                      isActive(`/${item.path}`)
                        ? "bg-light hover:bg-secondary"
                        : "bg-primary hover:bg-secondary"
                    )}
                    onSelect={() => (window.location.href = `/${item.path}`)}
                  >
                    <span
                      className="font-semibold text-white"
                      style={{ fontFamily: "PoppinsSemiBold" }}
                    >
                      {item.name}
                    </span>
                  </Dropdown.Item>
                ))}
              </Dropdown>
              {route.items.map((item, index) => (
                <Link
                  key={index}
                  to={`/${item.path}`}
                  className={classNames([
                    "flex lg:hidden items-center font-semibold hover:decoration-0 p-1 rounded",
                    isActive(`/${item.path}`)
                      ? "hover:bg-transparent"
                      : "hover:bg-secondary",
                  ])}
                >
                  <p
                    className={classNames([
                      "lg:hover:scale-105 lg:hover:text-secondary",
                      isActive(`/${item.path}`)
                        ? "text-light"
                        : "text-white lg:text-primary",
                    ])}
                    style={{ fontFamily: "PoppinsSemiBold" }}
                  >
                    {item.name}
                  </p>
                </Link>
              ))}
            </li>
          );
        } else {
          return (
            <li key={index}>
              <Link
                to={`/${route.path}`}
                className={classNames([
                  "flex items-center lg:hover:bg-transparent lg:p-0 font-semibold lg:ml-5 hover:decoration-0 p-1 rounded",
                  isActive(`/${route.path}`)
                    ? "hover:bg-transparent"
                    : "hover:bg-secondary",
                ])}
              >
                <p
                  className={classNames([
                    "lg:hover:scale-105 lg:hover:text-secondary",
                    isActive(`/${route.path}`)
                      ? "text-light"
                      : "text-white lg:text-primary",
                  ])}
                  style={{ fontFamily: "PoppinsSemiBold" }}
                >
                  {route.name}
                </p>
              </Link>
            </li>
          );
        }
      })}
    </ul>
  );

  return (
    <nav className="w-full relative backdrop-saturate-200 backdrop-blur-2xl bg-opacity-80 border-b border-b-primary z-[5]">
      <div className="flex items-center px-5 py-2">
        <Link to="/" className="flex hover:scale-105 mr-5">
          <img src="/logo.svg" alt="Logo" width={64} height={64} />
        </Link>

        <div className="hidden lg:flex lg:flex-1 justify-between items-center">
          <NavList />
          {userReducer.user?.role !== "patient" ? (
            <div
              key="Navbar_SearchContainer"
              className="lg:min-w-[400px] ml-auto lg:mr-5 border-0"
            >
              <InputGroup
                inside
                className="border-0 shadow-none drop-shadow-none"
              >
                <AutoComplete
                  key="Navbar_Search"
                  placeholder="Votre recherche"
                  data={searchItems}
                  value={searchValue}
                  onChange={getPatientItems}
                  menuClassName="relative z-50"
                  onSelect={(item) => {
                    switch (item.groupBy) {
                      case "patient":
                        window.location.href = `/patients/${item.value}`;
                        break;
                      case "prescriber":
                        window.location.href = `/prescripteurs/${item.value}`;
                        break;
                      case "admin":
                        //window.location.to = `/admin/${item.value}`;
                        break;
                      default:
                        return null;
                    }
                  }}
                  renderItem={(item) => (
                    <span className="flex flex-row gap-1 items-center">
                      {item.role === "prescriber" ? (
                        <FaUserMd color="var(--primary)" />
                      ) : (
                        <FaUserAlt color="var(--primary)" />
                      )}
                      {item.label}
                    </span>
                  )}
                />
                <InputGroup.Button size="lg">
                  <SearchIcon className="w-full h-full" />
                </InputGroup.Button>
              </InputGroup>
            </div>
          ) : null}
          <Dropdown
            title={
              <FaRegUser
                size={18}
                className={classNames(
                  "lg:hover:scale-105 hover:text-light",
                  menuOpen ? "text-light" : "text-primary"
                )}
              />
            }
            className="p-0 bg-transparent flex flex-col gap-3"
            placement="bottomEnd"
            toggleClassName="lg:hover:scale-105 !px-0 hover:!text-light"
            onToggle={(value) => {
              setMenuOpen(value);
              menuContext?.handleOpen(value);
            }}
          >
            <Dropdown.Item
              className={classNames(
                " relative top-[0.25rem] left-3 hover:!bg-secondary",
                isActive(`/account`) ? "!bg-light" : "!bg-primary"
              )}
              onSelect={() => (window.location.href = `/account`)}
            >
              <span className="text-white font-semibold">MON COMPTE</span>
            </Dropdown.Item>

            <div>
              <Dropdown.Item
                className="relative top-[.50rem] left-3 !bg-primary hover:!bg-secondary"
                onClick={() => {
                  menuContext?.handleOpen(false);
                  logoutHandle();
                }}
              >
                <span className="text-white font-semibold">DÉCONNEXION</span>
              </Dropdown.Item>
            </div>
          </Dropdown>
        </div>

        <div
          className="ml-auto lg:hidden cursor-pointer text-primary hover:text-light hover:scale-105"
          onClick={() => setOpenNav(!openNav)}
        >
          {openNav ? (
            <AiOutlineClose size={25} />
          ) : (
            <GiHamburgerMenu size={25} />
          )}
        </div>
      </div>

      <div
        id="MobileNav"
        className={classNames(
          openNav ? "flex lg:hidden" : "hidden",
          "bg-primary w-full h-full flex-col gap-2",
          openNav ? "p-5 min-h-[230px]" : "p-0"
        )}
      >
        <NavList />
        {userReducer.user?.role !== "patient" ? (
          <div key="Navbar_SearchContainer">
            <InputGroup
              inside
              className="border-0 shadow-none drop-shadow-none"
            >
              <AutoComplete
                key="Navbar_Search"
                placeholder="Votre recherche"
                data={searchItems}
                value={searchValue}
                menuClassName="relative z-50"
                onChange={getPatientItems}
                onSelect={(item) => {
                  switch (item.groupBy) {
                    case "patient":
                      window.location.href = `/patients/${item.value}`;

                      break;
                    case "prescriber":
                      window.location.href = `/prescripteurs/${item.value}`;
                      break;
                    case "admin":
                      //window.location.to = `/admin/${item.value}`;
                      break;
                    default:
                      return null;
                  }
                }}
                renderItem={(item) => (
                  <span className="flex flex-row gap-1 items-center">
                    {item.role === "prescriber" ? (
                      <FaUserMd color="var(--primary)" />
                    ) : (
                      <FaUserAlt color="var(--primary)" />
                    )}
                    {item.label}
                  </span>
                )}
              />
              <InputGroup.Button size="lg">
                <SearchIcon className="w-full h-full" />
              </InputGroup.Button>
            </InputGroup>
          </div>
        ) : null}
        <Link
          to="/account"
          className={classNames([
            "lg:hidden items-center font-semibold hover:decoration-0 p-1 rounded",
            isActive(`/account`)
              ? "hover:bg-transparent"
              : "hover:bg-secondary",
          ])}
        >
          <p
            className={classNames([
              "lg:hover:scale-105 lg:hover:text-dark",
              isActive(`/account`) ? "text-light" : "text-white",
            ])}
          >
            MON COMPTE
          </p>
        </Link>
        <button
          className="text-white bg-dark hover:bg-secondary rounded py-1 font-semibold mt-3"
          onClick={logoutHandle}
        >
          DÉCONNEXION
        </button>
      </div>
    </nav>
  );
};

export default NavBar;
