import moment from "moment";

const columns = () => [
  {
    name: "Début synchro",
    selector: (row) => `${row.startDate}`,
    sortable: true,
    sortfield: "startDate",
    cell: (row) => (
      <span data-tag="allowRowEvents">
        {moment(row.startDate).format("DD/MM/YYYY hh:mm")}
      </span>
    ),
  },
  {
    name: "Fin synchro",
    selector: (row) => `${row.endDate}`,
    sortable: true,
    sortfield: "endDate",
    cell: (row) => (
      <span data-tag="allowRowEvents">
        {moment(row.endDate).format("DD/MM/YYYY hh:mm")}
      </span>
    ),
  },
  {
    name: "Durée",
    selector: (row) => `${row.duration}`,
    sortable: true,
    sortfield: "duration",
    cell: (row) => <span data-tag="allowRowEvents">{row.durationString}</span>,
  },
  {
    name: "Nb. patient total",
    selector: (row) => `${row.countPatient}`,
    sortable: true,
    sortfield: "countPatient",
    cell: (row) => (
      <span data-tag="allowRowEvents" className="text-xs">
        {row.countPatient}
      </span>
    ),
  },
  {
    name: "Nb. nouveau patient",
    selector: (row) => `${row.countNewPatient}`,
    sortable: true,
    sortfield: "countNewPatient",
    cell: (row) => (
      <span data-tag="allowRowEvents" className="text-xs">
        {row.countNewPatient}
      </span>
    ),
  },
  {
    name: "Nb. patient modifié",
    selector: (row) => `${row.countUpPatient}`,
    sortable: true,
    sortfield: "countUpPatient",
    cell: (row) => <span data-tag="allowRowEvents">{row.countUpPatient}</span>,
  },
  {
    name: "Nb. patient renouvelable",
    selector: (row) => `${row.countRenewalPatient}`,
    sortable: true,
    sortfield: "countRenewalPatient",
    cell: (row) => (
      <span data-tag="allowRowEvents">{row.countRenewalPatient}</span>
    ),
  },
  {
    name: "Nb. requête échouée",
    selector: (row) => `${row.countFailedRequest}`,
    sortable: true,
    sortfield: "countFailedRequest",
    cell: (row) => (
      <span data-tag="allowRowEvents">{row.countFailedRequest}</span>
    ),
  },
];

export default columns;
