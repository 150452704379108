import { axiosManager, sanitizer } from "../../services";
import { apiCredentials } from "../apiCredentials";

const endpoint = apiCredentials.url + "/prescriptions/";
const prescriptionController = {
  getOne: async (id, access_token) => {
    const config = {
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
    };
    return await axiosManager.get(endpoint + id, config);
  },
  getAll: async (filters = [], access_token) => {
    const config = {
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
    };
    let params = "";
    filters.map((filter) => (params += `&${filter}`));
    return await axiosManager.get(endpoint + "?" + params, config);
  },
  getByPatient: (id, access_token) => {
    const config = {
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
    };
    return axiosManager.get(endpoint + "patient/" + id, config);
  },
  sign: async (id, data, access_token) => {
    const config = {
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
    };
    data = sanitizer(data);
    return await axiosManager.post(endpoint + id + "/sign", data, config);
  },
  getDocument: async (id, access_token) => {
    const config = {
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
      responseType: "blob",
    };
    return await axiosManager.get(endpoint + id + "/document", config);
  },
  reset: async (access_token) => {
    const config = {
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
    };
    return await axiosManager.post(endpoint + "reset", {}, config);
  },
};

export default prescriptionController;
