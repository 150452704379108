import { createContext, useState } from "react";

const MenuContext = createContext(null);

const MenuProvider = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleOpen = (value) => setIsOpen(value);

  return (
    <MenuContext.Provider value={{ isOpen, handleOpen }}>
      {children}
    </MenuContext.Provider>
  );
};

export { MenuContext, MenuProvider };
