const columns = () => [
  {
    name: "Date",
    selector: (row) => `${row.dateString}`,
    sortable: true,
    sortfield: "date",
    width: "180px",
    cell: (row) => <span data-tag="allowRowEvents">{row.dateString}</span>,
  },
  {
    name: "Méthode",
    selector: (row) => `${row.method}`,
    sortable: true,
    sortfield: "method",
    width: "120px",
    cell: (row) => <span data-tag="allowRowEvents">{row.method}</span>,
  },
  {
    name: "status",
    selector: (row) => `${row.status}`,
    sortable: true,
    sortfield: "status",
    width: "250px",
    cell: (row) => (
      <span data-tag="allowRowEvents">
        {row.status} - {row.statusText}
      </span>
    ),
  },
  {
    name: "url",
    selector: (row) => `${row.url}`,
    sortable: true,
    sortfield: "url",
    cell: (row) => (
      <span data-tag="allowRowEvents" className="text-xs">
        {row.url}
      </span>
    ),
  },
  {
    name: "Message",
    selector: (row) => `${row.data.title}`,
    sortable: true,
    sortfield: "data.title",
    width: "350px",
    cell: (row) => <span data-tag="allowRowEvents">{row.data.title}</span>,
  },
];

export default columns;
