/*
	Nettoie les <script> et les html entities présentes dans une string.
*/

import xss from "xss";
import _ from "lodash";

const defaultOptions = {
  whiteList: {}, // empty, means filter out all tags
  stripIgnoreTag: true, // filter out all HTML not in the whilelist
  stripIgnoreTagBody: ["script"], // the script tag is a special case, we need
  // to filter out its content
};

const sanitizer = (o, options) => {
  Object.keys(o).forEach(function (key) {
    if (_.isString(o[key])) {
      o[key] = xss(o[key], options || defaultOptions);
    } else if (_.isObject(o[key])) {
      Object.keys(o[key]).forEach(function (key2) {
        if (_.isString(o[key][key2])) {
          o[key][key2] = xss(o[key][key2], options || defaultOptions);
        }
      });
    }
  });
  return o;
};

export default sanitizer;
