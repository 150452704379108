import moment from "moment";
import ModalRenewal from "./modalRenewal";
import fileManager from "services/fileManager";

import { contractSettings } from "../constants";

const ElementRenewal = (props) => {
  const {
    prescription,
    patient,
    accessToken,
    downloadBtnHover,
    setDownloadBtnHover,
    setSettings,
    setStartDate,
    setEndDate,
    setCatheter,
    hasSignAccess,
  } = props;
  return (
    <div
      className="flex flex-row justify-between pb-1 md:10 lg:gap-20"
      id={`prescription-${prescription._id}`}
    >
      <div className="">
        <div className="font-semibold mb-2">
          {prescription.contract?.activity} -{" "}
          {prescription.contract?.plan?.label}
        </div>
        <div>
          <span className="font-semibold">Début :</span>{" "}
          <span>{moment(prescription.startDate).format("DD/MM/YYYY")}</span>
        </div>
        <div>
          <span className="font-semibold">Fin :</span>{" "}
          <span>{moment(prescription.endDate).format("DD/MM/YYYY")}</span>
        </div>
      </div>
      {hasSignAccess && prescription.isToRenew && !prescription.isSigned ? (
        <div className="flex flex-col justify-start items-end gap-4">
          {moment(prescription.startDate).isBefore(moment()) ? (
            <img src="/icons/renewal-late.svg" className="h-6 w-6" />
          ) : (
            <span className="mt-auto"></span>
          )}
          <div className="column is-narrow">
            <ModalRenewal
              id={`#prescription-${prescription._id}`}
              {...props}
              OpenComponent={({ onClick }) => (
                <button
                  className="border border-dark px-3 py-1 w-full font-semibold text-dark rounded hover:bg-dark hover:text-white"
                  onClick={() => {
                    setSettings(
                      contractSettings(prescription.contract.plan.code)
                    );
                    setStartDate(moment(prescription.startDate).toDate());
                    setEndDate(moment(prescription.endDate).toDate());
                    setCatheter(patient.catheter);
                    onClick();
                  }}
                >
                  Renouveler
                </button>
              )}
            />
          </div>
        </div>
      ) : null}
      {prescription.isSigned && prescription.status?.id === "Waiting" ? (
        <div className="flex flex-col justify-start items-end gap-4">
          <p className="font-light text-xs border border-validate-dark bg-validate p-1 px-2 rounded-full">
            Signée
          </p>
          <div className="column is-narrow w-fit">
            <button
              className="flex justify-between gap-1 items-center border border-dark px-3 py-1 w-full font-semibold text-dark rounded hover:bg-dark hover:text-white"
              onMouseEnter={() => setDownloadBtnHover(true)}
              onMouseLeave={() => setDownloadBtnHover(false)}
              onClick={async () =>
                await fileManager.downloadOrdo(prescription._id, accessToken)
              }
            >
              <p>Téléchager</p>
              <img
                src={`${
                  !downloadBtnHover
                    ? "/icons/renewal-download.svg"
                    : "/icons/renewal-download-alt.svg"
                }`}
                className="h-5 w-5"
              />
            </button>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default ElementRenewal;
