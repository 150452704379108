import moment from "moment";
import { patientModel } from "models";

const uniq = (a, key) => {
  const uniqKey = [];
  return a.filter((element) => {
    const isDuplicate = uniqKey.includes(element[key]);
    if (!isDuplicate) {
      uniqKey.push(element[key]);
      return true;
    }
    return false;
  });
};

const PatientInfo = ({ patient = patientModel }) => (
  <div>
    <div className="text-primary font-bold text-2xl mb-3">
      {patient.lastName} {patient.firstName}
    </div>
    <div className="flex flex-col gap-1">
      <div className={patient.birthDate ? "" : "hidden"}>
        {moment().diff(moment(patient.birthDate), "years")} ans
        <div className="mt-1">
          Né(e) le <span>{moment(patient.birthDate).format("DD/MM/YYYY")}</span>
        </div>
      </div>
      <div className={patient.linePhone ? "" : "hidden"}>
        <span className="font-semibold">Tel fixe :</span>{" "}
        <span>{patient.linePhone}</span>
      </div>
      <div className={patient.mobilePhone ? "" : "hidden"}>
        <span className="font-semibold">Tel mobile :</span>{" "}
        <span>{patient.mobilePhone}</span>
      </div>
      <div
        className={
          patient.email &&
          !patient.email.includes("@isisdiabete.fr") &&
          !patient.email.includes("ISISDIABETE.FR")
            ? ""
            : "hidden"
        }
      >
        <span className="font-semibold">Email :</span>{" "}
        <span>{patient.email}</span>
      </div>
      <div className={patient.socialSecurityNumber ? "" : "hidden"}>
        <span className="font-semibold">N° sécurité social : </span>
        {patient.socialSecurityNumber}
      </div>
      <span className={patient.location ? "" : "hidden"}>
        <span className="font-semibold">Adresse : </span>
        {patient.location?.streetNumber} {patient.location?.street},{" "}
        {patient.location?.zipCode} {patient.location?.city}
      </span>
    </div>
    <hr className="my-5" />
    <div className="flex flex-col gap-1">
      <div className={!patient.prescriber ? "hidden" : ""}>
        <span className="font-semibold">Prescripteur : </span>
        <span>{patient.prescriber}</span>
      </div>
      <div className={!patient.salesManager ? "hidden" : ""}>
        <span className="font-semibold">Responsable commercial : </span>
        <span>{patient.salesManager}</span>
      </div>
      <div
        className={
          !patient.nursingConsultant ? "hidden" : "flex flex-row gap-1"
        }
      >
        <span className="font-semibold">Infirmiers : </span>
        {Array.isArray(patient.nursingConsultant)
          ? uniq(patient.nursingConsultant, "name").map((nurse, index) => (
              <span key={index}>
                {nurse.name}{" "}
                {index < patient.nursingConsultant.length - 1 ? "-" : ""}
              </span>
            ))
          : null}
      </div>
      <div
        className={!patient.regionalManager ? "hidden" : "flex flex-row gap-1"}
      >
        <span className="font-semibold">Responsable de région : </span>
        {Array.isArray(patient.regionalManager)
          ? uniq(patient.regionalManager, "name").map((manager, index) => (
              <span key={index}>
                {manager.name}{" "}
                {index < patient.regionalManager.length - 1 ? "-" : ""}
              </span>
            ))
          : null}
      </div>
    </div>
  </div>
);

export default PatientInfo;
