import { axiosManager, sanitizer } from "../../services";
import { apiCredentials } from "../apiCredentials";

const endpoint = apiCredentials.url + "/devices/";
const deviceController = {
  create: (data, access_token) => {
    data = sanitizer(data);
    const config = {
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
    };
    return axiosManager.post(endpoint, data, config);
  },
  update: (deviceId, data, access_token) => {
    data = sanitizer(data);
    const config = {
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
    };
    return axiosManager.update(endpoint + deviceId, data, config);
  },
  getOne: (deviceId, access_token) => {
    const config = {
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
    };
    return axiosManager.get(endpoint + deviceId, config);
  },
  getAll: async (filters = [], access_token) => {
    const config = {
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
    };
    let params = "";
    filters.map((filter) => (params += filter));
    return await axiosManager.get(endpoint + "?" + params, config);
  },
  getPumps: async (access_token) => {
    const config = {
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
    };
    return await axiosManager.get(apiCredentials.url + "/pumps", config);
  },
  deleteOne: (deviceId, access_token) => {
    const config = {
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
    };
    return axiosManager.delete(endpoint + deviceId, config);
  },
};

export default deviceController;
