import { axiosManager } from "../../services";
import { apiCredentials } from "../apiCredentials";

const endpoint = apiCredentials.url + "/interventions/";
const interventionController = {
  getOne: (id, access_token) => {
    const config = {
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
    };
    return axiosManager.get(endpoint + id, config);
  },
  getMetrics: (patientId, access_token) => {
    const config = {
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
    };
    return axiosManager.get(endpoint + "metrics/" + patientId, config);
  },
  getDocument: async (interventionId, documentId, access_token) => {
    const config = {
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
      responseType: "blob",
    };
    return await axiosManager.get(
      endpoint + interventionId + "/document/" + documentId,
      config
    );
  },
  getAll: async (filters = [], access_token) => {
    const config = {
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
    };
    let params = "";
    filters.map((filter) => (params += `&${filter}`));
    return await axiosManager.get(endpoint + "?" + params, config);
  },
};

export default interventionController;
