import { axiosManager, sanitizer } from "../../services";
import { apiCredentials } from "../apiCredentials";

const endpoint = apiCredentials.url + "/users/";
const userController = {
  getUser: (userId, access_token) => {
    const config = {
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
    };
    return axiosManager.get(endpoint + userId, config);
  },
  getUserByEmail: (email, access_token) => {
    const config = {
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
    };
    return axiosManager.get(endpoint + email, config);
  },
  getAll: async (filters = [], access_token) => {
    const config = {
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
    };
    let params = "";
    filters.map((filter) => (params += `&${filter}`));
    return await axiosManager.get(endpoint + "?" + params, config);
  },
  createUser: (data, access_token) => {
    data = sanitizer(data);
    const config = {
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
    };
    return axiosManager.post(endpoint, data, config);
  },
  updatePassword: (data, access_token) => {
    data = sanitizer(data);
    const config = {
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
    };
    return axiosManager.update(endpoint + "password", data, config);
  },
};

export default userController;
