import { Loader } from "rsuite";

const CardMateriel = ({
  materiels = [],
  catheter = "",
  sensorType = "",
  isLoading = true,
}) => (
  <div className="shadow-xl rounded-xl h-fit">
    <div className="bg-primary text-white p-2 px-3 rounded-t-xl font-semibold">
      <p>MATÉRIEL</p>
    </div>
    <div className="bg-white p-3 rounded-b-xl flex flex-col gap-1 min-h-[50px]">
      {isLoading ? (
        <Loader />
      ) : materiels && Array.isArray(materiels) ? (
        materiels.map((materiel, index) => (
          <span key={materiel._id} className="flex flex-col gap-2">
            <div>
              <span className="font-semibold">Modèle de pompe :</span>{" "}
              <span>
                {typeof materiel.model === "string"
                  ? materiel.model.replace("POMPE ", "")
                  : "--"}
              </span>
            </div>
            <div>
              <span className="font-semibold">Modèle de cathéter :</span>{" "}
              <span>{catheter ? catheter : "--"}</span>
            </div>
            <div>
              <span className="font-semibold">Modèle de capteur :</span>{" "}
              <span>{sensorType ? sensorType : "--"}</span>
            </div>
            {index !== materiels.length - 1 ? (
              <hr className="bg-neutral-dark mt-1 mb-4" style={{ height: 1 }} />
            ) : null}
          </span>
        ))
      ) : null}
    </div>
  </div>
);

export default CardMateriel;
