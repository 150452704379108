import moment from "moment";

const CardTraitement = ({ traitement }) => (
  <div className="shadow-xl rounded-xl h-fit">
    <div className="bg-primary text-white p-2 px-3 rounded-t-xl font-semibold">
      <p>TRAITEMENT</p>
    </div>
    <div className="bg-white p-3 rounded-b-xl flex flex-col gap-2 min-h-[50px]">
      <div>
        <span className="font-semibold">Date installation :</span>{" "}
        <span>
          {traitement.installation
            ? moment(traitement.installation).format("DD/MM/YYYY")
            : "--"}
        </span>
      </div>
      <div>
        <span className="font-semibold">Type de diabète :</span>{" "}
        <span>
          {traitement.diabeteType?.label ? traitement.diabeteType?.label : "--"}
        </span>
      </div>
      <div>
        <span className="font-semibold">Insuline (DTQ) :</span>{" "}
        <span>
          {traitement.insuline} {traitement.insuline !== "--" ? "UI" : null}
        </span>
      </div>
      <div>
        <span className="font-semibold">Première HbA1c (%) connue :</span>{" "}
        <span>{traitement.firstHbA1c}</span>
      </div>
      <div>
        <span className="font-semibold">Dernière HbA1c (%) :</span>{" "}
        <span>{traitement.hba1c}</span>
      </div>
    </div>
  </div>
);

export default CardTraitement;
