import React, { useState, useEffect } from "react";
import { useParams, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { Viewer, Worker } from "@react-pdf-viewer/core";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import { saveAs } from "file-saver";

import { userSelector } from "redux/user/slice";
import { interventionController } from "api/controllers";
import { checkTypeDocDemo } from "utils";

// Import styles
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";

const useQuery = () => {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
};

const FileScreen = () => {
  const userReducer = useSelector(userSelector);
  const { type, id } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const defaultLayoutPluginInstance = defaultLayoutPlugin();
  const [file, setFile] = useState(null);
  const [fileType, setFileType] = useState(null);
  const [fileName, setFileName] = useState(null);
  let query = useQuery();

  useEffect(() => {
    async function defaultValue() {
      if (process.env.REACT_APP_DEMO) {
        setFile("/" + checkTypeDocDemo(query.get("type")));
        setFileName("doc");
        setFileType("application/pdf");
      } else {
        setFile(await getFile(type, id, query.get("doc")));
      }
      setIsLoading(false);
    }
    defaultValue();
  }, []);

  const getFile = async (type, id, docId) => {
    let response = { data: null };
    switch (type) {
      case "intervention":
        response = await interventionController.getDocument(
          id,
          docId,
          userReducer.userToken
        );
        break;
      default:
        return (response.data = null);
    }
    if (response.data) {
      setFileType(response.headers["content-type"]);
      setFileName(response.headers["content-disposition"]?.split('"')[1]);
      if (response.data?.size > 200) {
        return response.data &&
          response.headers["content-type"] === "application/pdf"
          ? URL.createObjectURL(response.data)
          : response.data;
      } else {
        return null;
      }
    } else {
      return null;
    }
  };
  const downloadFile = async () => {
    if (file && fileType && fileName) {
      saveAs(new Blob([file], { type: fileType }), fileName);
    }
  };

  if (isLoading) {
    return (
      <div className="text-neutral-dark text-lg flex justify-center items-center h-screen w-screen">
        Chargement en cours...
      </div>
    );
  }
  if (file) {
    if (fileType === "application/pdf") {
      return (
        <Worker workerUrl="/pdfWorker.js">
          <div>
            {file ? (
              <Viewer fileUrl={file} plugins={[defaultLayoutPluginInstance]} />
            ) : null}
          </div>
        </Worker>
      );
    } else {
      downloadFile();
      return null;
    }
  }

  return (
    <div className="text-neutral-dark text-lg flex justify-center items-center h-screen w-screen">
      Ce document n'est pas disponible.
    </div>
  );
};

export default FileScreen;
